import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MetricsTableData } from '..';
import { useGetDatapointValues } from './QuarterInput';
import { TimePeriodsEnums } from '../../Requirement';
import { QuestionType_Enum_ } from 'models';
import { VStack } from '@chakra-ui/react';
import { AutoResizeTextarea } from 'Atoms';
import { MenuSelector } from 'Molecules/Menu/MenuSelector';
import { debounce } from 'lodash';

export const ChoiceMetricInput = ({
  metric,
  companyReportingUnitId,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnitId?: string;
}) => {
  const options = useMemo(
    () =>
      metric.choiceSet?.choices.map((choice) => ({
        label: choice.title,
        value: choice.reference,
      })) ?? [],
    [metric]
  );

  const { onDatapointChange, dataPointPerYear, answer } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnitId
  );

  const choices = useMemo(
    () => dataPointPerYear?.datapointChoices.map((choice) => choice.choiceRef),
    [dataPointPerYear]
  );

  const handleChangeAnswer = (newChoices: string[] | undefined, value?: string) => {
    return onDatapointChange({
      value: value ?? dataPointPerYear?.value ?? '',
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
      choices: newChoices,
    });
  };

  const [inputValue, setInputValue] = useState(dataPointPerYear?.value ?? '');

  useEffect(() => {
    setInputValue(dataPointPerYear?.value ?? '');
  }, [dataPointPerYear?.value]);

  const debounceSave = useCallback(
    debounce((newChoices: string[] | undefined, value?: string) => {
      onDatapointChange({
        value: value ?? dataPointPerYear?.value ?? '',
        hasOptedOut: answer?.hasOptedOut ?? false,
        optOutReason: answer?.optOutReason ?? '',
        dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
        choices: newChoices,
      });
    }, 1000),
    [onDatapointChange, answer, dataPointPerYear]
  );

  return (
    <VStack alignItems="start" width="100%" spacing="2px">
      <MenuSelector
        isSingleSelect={metric.metricType === QuestionType_Enum_.SingleChoice_}
        options={options}
        handleChange={handleChangeAnswer}
        data={choices}
      />
      <AutoResizeTextarea
        variant="ghost"
        borderRadius="6px"
        lineHeight="20px"
        size="md"
        mb="4px"
        placeholder="Write your answer"
        value={inputValue ?? ''}
        onChange={({ target }) => {
          setInputValue(target.value);
          debounceSave(choices, target.value);
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </VStack>
  );
};
