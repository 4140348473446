import {
  Box,
  Collapse,
  HStack,
  useDisclosure,
  VStack,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { Button, ExternalLink, IconButton, Tag, VisibleIf } from 'Atoms';
import { Typography } from 'Tokens';
import {
  ActivityReport_Constraint_,
  ActivityReport_Update_Column_,
  CompanyAssessment,
  Financials_Insert_Input_,
  GetFinancialsDocument_,
  useUpdateFinancialsMutation,
} from 'models';
import { ContentHeader, ContentLayout } from 'Molecules';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  CompanyFinancialResults,
  toCSVString,
  useContainsNegativeNumbers,
  useFinancials,
} from './Financials.hooks';
import {
  BusinessUnitFinancialTable,
  CompanyNotEligibleTable,
  FinancialSummary,
  TotalTable,
} from './FinancialTable';
import { BareFinancials, Financials, ReportingGroup } from '../../models/types';
import { GroupFinancials } from './GroupFinancials';
import { useUserSetting } from '../../containers/Navigation/Navigation.hooks';
import { useCurrentCompany, useToast } from 'utils/hooks';
import {
  aggregateFinancials,
  createBareFinancials,
  createFinancials,
  getOtherFinancials,
} from 'utils/financials';
import { useKnowledgeBase } from 'Features/KnowledgeBase';
import { Infobox } from 'Atoms/Infobox';
import { useTranslation } from 'utils/translation';
import { CSVUploader } from 'Features/CSVUploader';
import { groupBy, uniqBy } from 'lodash';
import { InfoIcon, LoaderIcon } from 'Tokens/Icons/Status';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { UploadIcon } from 'Tokens/Icons/Function';
import { useFlagAndUnlockAssessment } from 'containers/Assessments/Assessments.hooks';
import { useBuildTree } from 'Features/CompanyStructure/useBuildTree';
import { NodeItem } from 'Features/CompanyStructure';
import { getDescendants } from '@minoru/react-dnd-treeview';
import { NegativeNumbersWarningBox } from './NegativeNumbersWarningBox';
import { BusinessUnitsSortingType } from 'containers/Assessments/pieces/Assessment.hooks';
import { ApolloError } from '@apollo/client/errors';
import { useDebounce } from 'usehooks-ts';
import {
  ARTICLE_ADAPTATION_CAPEX_OPEX,
  ARTICLE_WHAT_IS_CAPEX,
  ARTICLE_WHAT_IS_OPEX,
  ARTICLE_WHAT_IS_TURNOVER,
} from 'Features/KnowledgeBase/KnowledgeBaseArticles';

const ConfirmUpload = () => {
  const { t } = useTranslation('csv');
  return (
    <VStack justifyContent="flex-start" alignItems="flex-start" width="100%">
      <Typography variant="body" color="text.default">
        {t('csv:text.newData')}
      </Typography>
      <Box bg="bg.unknown.light" borderRadius="8px" p="16px">
        <UnorderedList>
          <ListItem>
            <Typography variant="body" color="text.default">
              {t('csv:text.populate')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body" color="text.default">
              {t('csv:text.override')}
            </Typography>
          </ListItem>
        </UnorderedList>
      </Box>
      <Typography variant="body" color="text.default">
        {t('csv:text.remember')}
      </Typography>
    </VStack>
  );
};

enum FinancialFilter {
  all = 'all',
  byId = 'byId',
}

const FilteredFinancials = React.memo(
  ({
    filter,
    reportingGroups,
    financials,
    selectedFinancials,
    onUpdate,
    isLocked,
    isSaving,
    saveError,
    isLoading,
  }: {
    filter: FinancialFilter;
    reportingGroups: ReportingGroup[];
    financials: CompanyFinancialResults;
    selectedFinancials?: CompanyFinancialResults['businessUnits'][number];
    onUpdate: (financials: CompanyFinancialResults) => void;
    isLocked: boolean;
    isSaving: boolean;
    isLoading: boolean;
    saveError: ApolloError | undefined;
  }) => {
    const [notEligible, setNotEligible] = useState<BareFinancials>();

    useEffect(() => {
      const notEl = getOtherFinancials(
        financials?.businessUnits.map((b) => b.financials ?? undefined),
        financials.companyFinancials
      );
      setNotEligible(notEl);
    }, [financials]);

    const calculateNewCompanyFinancials = (
      businessUnits: CompanyFinancialResults['businessUnits'],
      newNotEligible?: BareFinancials
    ) => {
      const companyTotal = aggregateFinancials([
        ...(businessUnits.map((bu) => bu.financials) as BareFinancials[]),
        (newNotEligible ? newNotEligible : notEligible) as BareFinancials,
      ]);
      return companyTotal;
    };
    if (filter === FinancialFilter.byId && selectedFinancials) {
      return (
        <BusinessUnitFinancialTable
          key={selectedFinancials.businessUnit?.id}
          businessUnit={selectedFinancials}
          isLocked={isLocked}
          onUpdate={(bu) => {
            const updatedBusinessUnits = financials.businessUnits.map((curBu) => {
              if (curBu.businessUnit?.id === bu.businessUnit?.id) {
                return bu;
              }
              return curBu;
            });
            onUpdate({
              ...financials,
              companyFinancials: {
                ...financials.companyFinancials,
                ...calculateNewCompanyFinancials(updatedBusinessUnits),
              } as Financials,
              businessUnits: updatedBusinessUnits,
            });
          }}
          isSaving={isSaving}
          saveError={saveError}
        />
      );
    }
    return (
      <VStack spacing="16px" paddingBottom="16px" width="100%" alignItems="flex-start">
        {reportingGroups
          .filter((item) => item.parentId === null)
          .map((rg) => (
            <GroupFinancials
              key={rg.id}
              reportingGroup={rg}
              children={reportingGroups.filter((child) => child.parentId === rg.id)}
              isLocked={isLocked}
              businessUnits={financials.businessUnits.filter((bu) =>
                rg.businessUnits.find((rgBu) => rgBu.businessUnit?.id === bu.businessUnit?.id)
              )}
              allReportingGroups={reportingGroups}
              allBusinessUnits={financials.businessUnits}
              bg="solid"
              onUpdate={(bu) => {
                const updatedBusinessUnits = financials.businessUnits.map((curBu) => {
                  if (curBu.businessUnit?.id === bu.businessUnit?.id) {
                    return bu;
                  }
                  return curBu;
                });
                onUpdate({
                  ...financials,
                  companyFinancials: {
                    ...financials.companyFinancials,
                    ...calculateNewCompanyFinancials(updatedBusinessUnits),
                  } as Financials,
                  businessUnits: updatedBusinessUnits,
                });
              }}
              isSaving={isSaving}
              saveError={saveError}
            />
          ))}
        {financials.businessUnits
          .filter(
            (bu) =>
              !reportingGroups.find((grp) =>
                grp.businessUnits.find((grpBu) => grpBu.businessUnit?.id === bu.businessUnit?.id)
              )
          )
          .map((bu) => (
            <BusinessUnitFinancialTable
              key={bu.financials?.id}
              businessUnit={bu}
              isLocked={isLocked}
              onUpdate={(newBu) => {
                const updatedBusinessUnits = financials.businessUnits.map((curBu) => {
                  if (curBu.businessUnit?.id === newBu.businessUnit?.id) {
                    return newBu;
                  }
                  return curBu;
                });
                onUpdate({
                  ...financials,
                  companyFinancials: {
                    ...financials.companyFinancials,
                    ...calculateNewCompanyFinancials(updatedBusinessUnits),
                  } as Financials,
                  businessUnits: updatedBusinessUnits,
                });
              }}
              isSaving={isSaving}
              saveError={saveError}
            />
          ))}
        <CompanyNotEligibleTable
          isLoading={isLoading}
          isEstimate={financials?.companyFinancials?.isEstimate ?? true}
          notEligible={notEligible ?? createBareFinancials(0, 0, 0, 0, 0)}
          isLocked={isLocked}
          onUpdate={(val) => {
            const newCompanyFinancials = calculateNewCompanyFinancials(
              financials.businessUnits,
              val
            );
            onUpdate({
              ...financials,
              companyFinancials: {
                ...financials.companyFinancials,
                ...newCompanyFinancials,
              } as Financials,
            });
          }}
          isSaving={isSaving}
          saveError={saveError}
        />
        <FinancialSummary companyResults={financials} isLocked={isLocked} />
        <TotalTable
          companyResults={financials}
          isLocked={isLocked}
          onUpdate={onUpdate}
          isSaving={isSaving}
          saveError={saveError}
        />
      </VStack>
    );
  }
);

export const CompanyFinancials = ({
  cAssessment,
  reportingGroups,
  businessUnitsSorting,
}: {
  cAssessment: CompanyAssessment;
  reportingGroups: ReportingGroup[];
  businessUnitsSorting: BusinessUnitsSortingType;
}) => {
  const toast = useToast();
  const [shouldShowHelp, setShouldShowHelp, { loading }] = useUserSetting(
    'show-financials-help',
    true
  );

  const { sortAlphabetically, groupsFirst } = businessUnitsSorting;
  const { onOpen } = useKnowledgeBase();
  const { company } = useCurrentCompany();
  const [updateFinancials, { loading: isLoadingUpdateFinancials, error: saveError }] =
    useUpdateFinancialsMutation();
  const [flagAndUnlockAssessment, { loading: isLoadingFlagAndUnlockAssessment }] =
    useFlagAndUnlockAssessment();

  const { financials, loading: loadingFinancials } = useFinancials({
    cAssessmentId: cAssessment?.id,
    sortAlphabetically,
    groupsFirst,
  });

  const [isSaving, setIsSaving] = useState<boolean>(false);
  useEffect(() => {
    setIsSaving(isLoadingUpdateFinancials || isLoadingFlagAndUnlockAssessment || loadingFinancials);
  }, [isLoadingUpdateFinancials, isLoadingFlagAndUnlockAssessment, loadingFinancials]);

  const [localFinancials, setLocalFinancials] = React.useState<CompanyFinancialResults>(financials);
  const [searchParams] = useSearchParams();
  const debouncedFinancials = useDebounce(localFinancials, 750);
  const { t } = useTranslation(['common', 'csv']);

  const containsNegativeFinancials = useContainsNegativeNumbers(cAssessment?.id);

  useEffect(() => {
    if (loadingFinancials) return;
    if (!financials.totalFinancials && !cAssessment?.isLocked) {
      const totalFinancialsCreates = { ...createFinancials(), cAssessmentId: cAssessment?.id };
      updateFinancials({
        variables: {
          financialsToUpdate: [],
          financialsToCreate: totalFinancialsCreates,
        },
        refetchQueries: [GetFinancialsDocument_],
      });
    }
    if (!!financials.companyFinancials) {
      setLocalFinancials(financials);
    }
  }, [financials, loadingFinancials]);

  useEffect(() => {
    if (
      financials &&
      debouncedFinancials &&
      JSON.stringify(debouncedFinancials) !== JSON.stringify(financials) &&
      !isSaving
    ) {
      const updates: Financials_Insert_Input_[] = [
        ...(debouncedFinancials?.businessUnits ?? [])
          .filter((b) => !!b.financials?.id)
          .map((bu) => ({
            id: bu.financials?.id,
            capex: bu.financials?.capex,
            opex: bu.financials?.opex,
            revenue: bu.financials?.revenue,
            isEstimate: bu.financials?.isEstimate ?? true,
            adaptationCapex: bu.financials?.adaptationCapex,
            adaptationOpex: bu.financials?.adaptationOpex,
            reportId: bu.financials?.id,
          })),
        ...(debouncedFinancials?.businessUnits ?? [])
          .map((bu) => bu.activities ?? [])
          .flat()
          .filter((a) => !!a.financials?.id)
          .map((a) => ({
            id: a?.financials?.id,
            capex: a?.financials?.capex,
            opex: a?.financials?.opex,
            revenue: a?.financials?.revenue,
            isEstimate: a?.financials?.isEstimate ?? true,
            adaptationCapex: a?.financials?.adaptationCapex,
            adaptationOpex: a?.financials?.adaptationOpex,
            reportId: a.financials?.id,
          })),
        ...(debouncedFinancials?.companyFinancials?.id
          ? [
              {
                id: debouncedFinancials?.companyFinancials?.id,
                capex: debouncedFinancials?.companyFinancials?.capex,
                opex: debouncedFinancials?.companyFinancials?.opex,
                revenue: debouncedFinancials?.companyFinancials?.revenue,
                isEstimate: debouncedFinancials?.companyFinancials?.isEstimate ?? true,
                adaptationCapex: debouncedFinancials?.companyFinancials?.adaptationCapex,
                adaptationOpex: debouncedFinancials?.companyFinancials?.adaptationOpex,
                reportId: debouncedFinancials?.companyFinancials?.id,
              },
            ]
          : []),
        ...(debouncedFinancials?.totalFinancials?.id
          ? [
              {
                id: debouncedFinancials?.totalFinancials?.id,
                capex: debouncedFinancials?.totalFinancials?.capex,
                opex: debouncedFinancials?.totalFinancials?.opex,
                revenue: debouncedFinancials?.totalFinancials?.revenue,
                isEstimate: debouncedFinancials?.totalFinancials?.isEstimate ?? true,
                adaptationCapex: debouncedFinancials?.totalFinancials?.adaptationCapex,
                adaptationOpex: debouncedFinancials?.totalFinancials?.adaptationOpex,
                reportId: null,
              },
            ]
          : []),
      ];
      const activitiesWithoutFinancials = debouncedFinancials?.businessUnits
        .map((bu) => bu.activities)
        .flat()
        .filter((a) => !a.financials?.id);

      const creates = (activitiesWithoutFinancials ?? []).map((a) => ({
        capex: a?.financials?.capex ?? 0,
        opex: a?.financials?.opex ?? 0,
        revenue: a?.financials?.revenue ?? 0,
        isEstimate: a?.financials?.isEstimate ?? true,
        adaptationCapex: a?.financials?.adaptationCapex ?? 0,
        adaptationOpex: a?.financials?.adaptationOpex ?? 0,
        activityReport: {
          data: {
            id: a?.id,
            bAssessmentId: a?.bAssessmentId,
            activityRef: a?.activity?.reference,
          },
          on_conflict: {
            constraint:
              ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
            update_columns: [ActivityReport_Update_Column_.CreatedAt_],
          },
        },
        noteHistory: { data: {} },
        attachmentBox: { data: {} },
      }));

      try {
        if (updates.length) {
          if (
            updates.some(
              (update) =>
                update.capex < update.adaptationCapex || update.opex < update.adaptationOpex
            ) ||
            creates.some(
              (create) =>
                create.capex < create.adaptationCapex || create.opex < create.adaptationOpex
            )
          ) {
            throw new Error(
              'Capex and/or Opex values cannot be lower than their respective Adaptation values'
            );
          }
          updateFinancials({
            variables: {
              financialsToUpdate: updates,
              financialsToCreate: creates,
            },
            refetchQueries: [GetFinancialsDocument_],
          }).then(() => {
            flagAndUnlockAssessment(cAssessment);
            toast({
              text: t('common:assessment.companyFinancials.updated'),
              closable: true,
            });
          });
        }
      } catch (e: any) {
        toast({
          text: ` ${t('common:assessment.companyFinancials.errorUpdating')} ${e.message}`,
          variant: 'danger',
          closable: true,
          destroyAll: true,
        });
      }
    }
  }, [debouncedFinancials, isSaving]);

  const { filter, id } = useMemo(() => {
    const nodeType = searchParams.get('nodeType');
    const nodeId = searchParams.get('nodeId');
    if (nodeType === 'company') {
      return { filter: FinancialFilter.all, id: nodeId };
    }
    if (nodeType === 'businessUnit') return { filter: FinancialFilter.byId, id: nodeId };
    return { filter: FinancialFilter.all, id: 'company' };
  }, [searchParams]);

  const selectedFinancials = useMemo(() => {
    return localFinancials?.businessUnits.find((bu) => bu.businessUnit?.id === id);
  }, [localFinancials, filter, id]);
  const { isOpen: isCSVUploadOpen, onOpen: csvOpen, onClose } = useDisclosure();
  const {
    isOpen: isCSVConfirmOpen,
    onOpen: onCSVConfirmOpen,
    onClose: onCSVConfirmClose,
  } = useDisclosure();

  const DEFAULT_DESCRIPTIONS = [
    {
      title: t('csv:steps.financialStep1'),
      description: t('csv:steps.financialDesc1'),
      button: t('csv:button.download'),
    },
    {
      title: t('csv:steps.financialStep2'),
      description: t('csv:steps.financialDesc2'),
    },
    {
      title: t('csv:steps.financialStep3'),
      description: t('csv:steps.financialDesc3'),
    },
    {
      title: t('csv:steps.financialStep4'),
    },
  ];

  const treeData: NodeItem[] = useBuildTree(
    reportingGroups,
    localFinancials.businessUnits.map((bu) => ({
      id: bu.id,
      businessUnit: { id: bu.businessUnit?.id ?? '', name: bu.businessUnit?.name ?? '' },
      reportingGroup: { id: bu.reportingGroupId ?? null },
      orderIndex: bu.orderIndex,
    })),
    'company',
    '',
    sortAlphabetically,
    groupsFirst
  );
  const descendants: NodeItem[] = getDescendants<NodeItem['data']>(treeData, 'company');

  const getChildren = (parentId: string, descendantsArray: NodeItem[]): NodeItem[] => {
    return descendantsArray
      .filter((d) => d.parent === parentId)
      .flatMap((node) =>
        node.data?.type === 'businessUnit' && node.parent === parentId
          ? node
          : getChildren(node.data?.original?.id, descendantsArray)
      );
  };

  const orderedTree =
    descendants
      .flatMap((node) =>
        node.data?.type === 'businessUnit' && node.parent === 'company'
          ? node
          : node.data?.type === 'reportingGroup'
            ? getChildren(node.data?.original?.id, descendants).flat() ?? [node.text]
            : undefined
      )
      .filter((value) => value !== undefined) ?? ([] as NodeItem[]);

  const dataToCSV: string[][] = useMemo(() => {
    const orderedFinancials = uniqBy(orderedTree, 'id').map((node) => {
      return (
        localFinancials.businessUnits.find((bu) => bu?.businessUnit?.id === node?.id) ??
        ({} as CompanyFinancialResults['businessUnits'][number])
      );
    });

    const data = [
      [
        'Reporting unit name',
        'Reporting unit labels',
        'Activity name',
        'Turnover',
        'CapEx',
        'OpEx',
        'Adaptation CapEx',
        'Adaptation OpEx',
      ],
    ];

    orderedFinancials?.forEach((bu) => {
      bu.activities.forEach((activity) =>
        data.push([
          toCSVString(bu.businessUnit?.name),
          toCSVString(bu.businessUnit?.labels.join(', ')),
          toCSVString(activity.activity?.name),
          toCSVString(activity.financials?.revenue),
          toCSVString(activity.financials?.capex),
          toCSVString(activity.financials?.opex),
          toCSVString(activity.financials?.adaptationCapex),
          toCSVString(activity.financials?.adaptationOpex),
        ])
      );
      const buNonEl = getOtherFinancials(
        bu.activities.map((act) => act.financials ?? undefined),
        bu.financials ?? undefined
      );
      data.push([
        toCSVString(bu.businessUnit?.name),
        toCSVString(bu.businessUnit?.labels.join(', ')),
        'Taxonomy-non-eligible activities',
        toCSVString(buNonEl?.revenue ?? 0),
        toCSVString(buNonEl?.capex ?? 0),
        toCSVString(buNonEl?.opex ?? 0),
        toCSVString(buNonEl?.adaptationCapex ?? 0),
        toCSVString(buNonEl?.adaptationOpex ?? 0),
      ]);
    });
    const companyNonEl = getOtherFinancials(
      localFinancials.businessUnits.map((b) => b.financials ?? undefined) ?? [],
      localFinancials.companyFinancials ?? undefined
    );
    data.push([
      'Company-others-taxonomy-non-eligible',
      '',
      'Taxonomy-non-eligible activities',
      toCSVString(companyNonEl?.revenue ?? 0),
      toCSVString(companyNonEl?.capex ?? 0),
      toCSVString(companyNonEl?.opex ?? 0),
      toCSVString(companyNonEl?.adaptationCapex ?? 0),
      toCSVString(companyNonEl?.adaptationOpex ?? 0),
    ]);
    return data;
  }, [localFinancials, reportingGroups]);

  type CSVData = {
    reportingUnitName: string;
    activityName: string;
    revenue?: number;
    capex?: number;
    opex?: number;
    adaptationCapex?: number;
    adaptationOpex?: number;
  }[];

  const IsCapexOpexValid = (data?: CSVData, newFinancials?: CompanyFinancialResults) => {
    let capexRes = false;
    let opexRes = false;
    let invalid = false;

    const isInvalid = () => {
      if (data) {
        capexRes = data.some(
          (row) => row.capex && row.adaptationCapex && row.capex < row.adaptationCapex
        );
        opexRes = data.some(
          (row) => row.opex && row.adaptationOpex && row.opex < row.adaptationOpex
        );
      }
      if (newFinancials) {
        if (
          newFinancials.companyFinancials?.capex <
            newFinancials.companyFinancials?.adaptationCapex ||
          newFinancials.companyFinancials?.opex < newFinancials.companyFinancials?.adaptationOpex
        )
          return true;

        const companyNonEl = getOtherFinancials(
          newFinancials.businessUnits.map((bu) => bu.financials ?? undefined),
          newFinancials.companyFinancials
        );
        if (
          companyNonEl.capex < companyNonEl.adaptationCapex ||
          companyNonEl.opex < companyNonEl.adaptationOpex
        )
          return true;

        newFinancials.businessUnits.forEach((bu) => {
          if (!invalid) {
            if (
              bu.financials?.capex < bu.financials?.adaptationCapex ||
              bu.financials?.opex < bu.financials?.adaptationOpex
            )
              invalid = true;
            if (!invalid) {
              bu.activities.forEach((activity) => {
                if (
                  activity.financials?.capex < activity.financials?.adaptationCapex ||
                  activity.financials?.opex < activity.financials?.adaptationOpex
                )
                  invalid = true;
              });
            }
            if (!invalid) {
              {
                const nonEl = getOtherFinancials(
                  bu.activities.map((act) => act.financials ?? undefined),
                  bu.financials ?? undefined
                );
                if (nonEl.capex < nonEl.adaptationCapex || nonEl.opex < nonEl.adaptationOpex)
                  invalid = true;
              }
            }
          }
        });
      }
      if (capexRes || opexRes || invalid) return true;
      return false;
    };
    if (isInvalid()) {
      toast({
        text: t('csv:error.capexOpex'),
        variant: 'danger',
        closable: true,
        duration: null,
      });
      return true;
    }
    return false;
  };

  const getAllActivities = (
    oldActivities: CompanyFinancialResults['businessUnits'][number]['activities'],
    newActivities: CSVData
  ) => {
    const allActivities = oldActivities.map(
      (actInFinancials: CompanyFinancialResults['businessUnits'][number]['activities'][number]) => {
        const activityInCsv = newActivities.find(
          (actInCSV: CSVData[number]) =>
            actInCSV.activityName.toLowerCase() === actInFinancials.activity?.name.toLowerCase()
        );
        if (activityInCsv) {
          return {
            ...actInFinancials,
            financials: {
              adaptationCapex:
                activityInCsv.adaptationCapex ?? actInFinancials.financials?.adaptationCapex,
              adaptationOpex:
                activityInCsv.adaptationOpex ?? actInFinancials.financials?.adaptationOpex,
              capex: activityInCsv.capex ?? actInFinancials.financials?.capex,
              id: actInFinancials.financials?.id,
              isEstimate: actInFinancials.financials?.isEstimate ?? true,
              opex: activityInCsv.opex ?? actInFinancials.financials?.opex,
              revenue: activityInCsv.revenue ?? actInFinancials.financials?.revenue,
            },
          };
        } else {
          return { ...actInFinancials };
        }
      }
    );
    return allActivities;
  };

  const getTotal = (
    data:
      | CompanyFinancialResults['businessUnits'][number]['financials'][]
      | Record<string, never>[],
    newFinancials: CSVData[number] | undefined,
    oldFinancials: BareFinancials
  ) => {
    return aggregateFinancials([
      ...data.map((d) => (d ? d : {})),
      {
        adaptationCapex: newFinancials?.adaptationCapex ?? oldFinancials.adaptationCapex,
        adaptationOpex: newFinancials?.adaptationOpex ?? oldFinancials.adaptationOpex,
        capex: newFinancials?.capex ?? oldFinancials.capex,
        opex: newFinancials?.opex ?? oldFinancials.opex,
        revenue: newFinancials?.revenue ?? oldFinancials.revenue,
      },
    ]);
  };

  const updateBUFinancials = (groupByRU: { [key: string]: CSVData }) => {
    const allCSVBu: CompanyFinancialResults['businessUnits'] = [];
    Object.entries(groupByRU).forEach(([buName, activities]) => {
      const buOldFinancials = localFinancials.businessUnits.find(
        (bu: CompanyFinancialResults['businessUnits'][number]) => bu.businessUnit?.name === buName
      );
      if (buOldFinancials) {
        const notEligibleBU = activities.find(
          (row) => row.activityName.toLowerCase() === 'taxonomy-non-eligible activities'
        );

        const oldNotEligibleBU = getOtherFinancials(
          buOldFinancials.activities.map((act) => act.financials ?? undefined),
          buOldFinancials?.financials ?? undefined
        );

        const allActivities = getAllActivities(buOldFinancials?.activities, activities);
        const buTotal = getTotal(
          allActivities.map((act) => act.financials ?? {}),
          notEligibleBU,
          oldNotEligibleBU
        );

        const buFinancials = {
          ...buOldFinancials,
          activities: allActivities,
          financials: {
            ...buTotal,
            isEstimate: buOldFinancials?.financials?.isEstimate ?? true,
            id: buOldFinancials?.financials?.id,
          },
        };

        allCSVBu.push(buFinancials);
      }
    });
    return allCSVBu;
  };

  const getFinalFinancials = (
    allBuFinancials: CompanyFinancialResults['businessUnits'][number]['financials'][],
    companyNotEl: CSVData[number] | undefined,
    allCSVBu: CompanyFinancialResults['businessUnits']
  ) => {
    const oldCompanyNotEl = getOtherFinancials(
      localFinancials.businessUnits.map((bu) => bu.financials ?? undefined),
      localFinancials.companyFinancials
    );

    const cuTotal = getTotal(allBuFinancials, companyNotEl, oldCompanyNotEl);

    const unchangedBU: CompanyFinancialResults['businessUnits'][number][] =
      localFinancials.businessUnits.filter((bu) =>
        allCSVBu.every(
          (b: CompanyFinancialResults['businessUnits'][number]) =>
            bu.businessUnit?.id !== b.businessUnit?.id
        )
      );

    const newBuFinancials: CompanyFinancialResults = {
      companyFinancials: {
        ...cuTotal,
        isEstimate: localFinancials.companyFinancials?.isEstimate ?? true,
        id: localFinancials.companyFinancials?.id,
      },
      businessUnits: [...unchangedBU, ...allCSVBu],
    };
    return newBuFinancials;
  };

  const onUploadDone = (rows: CSVData) => {
    const groupByRU = groupBy(rows, 'reportingUnitName');
    if (IsCapexOpexValid(rows)) {
      return true;
    }
    toast({
      text: t('csv:toast.uploading'),
      closable: false,
      icon: <LoaderIcon color="white" />,
    });
    const companyNotEl: CSVData[number] | undefined = groupByRU[
      'Company-others-taxonomy-non-eligible'
    ]
      ? groupByRU['Company-others-taxonomy-non-eligible'][0]
      : undefined;
    delete groupByRU['Company-others-taxonomy-non-eligible'];

    const allCSVBu = updateBUFinancials(groupByRU);
    const allBuFinancials: CompanyFinancialResults['businessUnits'][number]['financials'][] =
      allCSVBu.map((bu) => bu.financials);
    localFinancials.businessUnits
      .filter((bu) => !Object.entries(groupByRU).some((b) => b[0] === bu.businessUnit?.name))
      .map((f) => allBuFinancials.push(f.financials));

    const financialsResult = getFinalFinancials(allBuFinancials, companyNotEl, allCSVBu);

    const isColumnAlone = rows.every(
      (row) =>
        (row.capex && !row.adaptationCapex) ||
        (row.adaptationCapex && !row.capex) ||
        (row.opex && !row.adaptationOpex) ||
        (row.adaptationOpex && !row.opex)
    );

    if (isColumnAlone) {
      if (IsCapexOpexValid(undefined, financialsResult)) {
        return true;
      }
    }
    setLocalFinancials(financialsResult);
  };

  return (
    <ContentLayout
      variant="inline"
      header={
        <ContentHeader
          title={t('common:assessment.financials')}
          size="md"
          actions={
            <IconButton
              variant="ghost"
              icon={<InfoIcon color="inherit" />}
              aria-label="info"
              onClick={() => setShouldShowHelp(!shouldShowHelp)}
              tooltipLabel={t('common:actions.help')}
            />
          }
        />
      }
    >
      <VStack width="100%" justifyContent="flex-start" paddingY="0px" pr="16px">
        <HStack
          as={Collapse}
          in={shouldShowHelp && !loading}
          width="100%"
          justifyContent="stretch"
          marginBottom="14px"
        >
          <Infobox
            title={t('common:assessment.companyFinancials.info.title')}
            description={t('common:assessment.companyFinancials.info.description')}
            status="neutral"
            closable
            onClose={() => {
              setShouldShowHelp(false);
            }}
            extra={
              <VStack spacing="4px" width="100%" alignItems="flex-start">
                <ExternalLink onClick={() => onOpen(ARTICLE_WHAT_IS_TURNOVER.slug)}>
                  {t('common:assessment.companyFinancials.turnover')}
                </ExternalLink>
                <ExternalLink onClick={() => onOpen(ARTICLE_WHAT_IS_CAPEX.slug)}>
                  {t('common:assessment.companyFinancials.capex')}
                </ExternalLink>
                <ExternalLink onClick={() => onOpen(ARTICLE_WHAT_IS_OPEX.slug)}>
                  {t('common:assessment.companyFinancials.opex')}
                </ExternalLink>
                <ExternalLink onClick={() => onOpen(ARTICLE_ADAPTATION_CAPEX_OPEX.slug)}>
                  {t('common:assessment.companyFinancials.adaptation')}
                </ExternalLink>
              </VStack>
            }
          />
        </HStack>
        <HStack width="full" height="64px" justifyContent="space-between">
          <HStack>
            <Typography variant="body">{t('common:words.view')} </Typography>
            {filter === FinancialFilter.byId ? (
              <Tag
                borderRadius="32px"
                padding="8px 16px"
                borderWidth="1px"
                borderColor="border.default"
                bg="bg.default"
              >
                <Typography variant="bodyStrong">
                  {selectedFinancials?.businessUnit?.name}
                </Typography>
              </Tag>
            ) : (
              <HStack>
                <CompanyIcon />
                <Typography variant="bodyStrong">{company?.name}</Typography>
              </HStack>
            )}
          </HStack>
          <HStack>
            <VisibleIf condition={!cAssessment?.isLocked}>
              <Button
                variant="primary"
                leftIcon={<UploadIcon color="inherit" />}
                onClick={() => csvOpen()}
              >
                {t('csv:button.uploadCSV')}
              </Button>
            </VisibleIf>
            <CSVUploader
              onOpen={csvOpen}
              onClose={onClose}
              isOpen={isCSVUploadOpen}
              title={t('csv:title.uploadFinancial')}
              confirmModal={<ConfirmUpload />}
              dataToCSV={dataToCSV}
              description={DEFAULT_DESCRIPTIONS}
              columns={[
                {
                  title: 'Reporting unit name',
                  key: 'reportingUnitName',
                  required: true,
                  type: 'string',
                },
                { title: 'Activity name', key: 'activityName', required: true, type: 'string' },
                { title: 'Turnover', key: 'revenue', required: false, type: 'number' },
                { title: 'CapEx', key: 'capex', required: false, type: 'number' },
                { title: 'OpEx', key: 'opex', required: false, type: 'number' },
                {
                  title: 'Adaptation CapEx',
                  key: 'adaptationCapex',
                  required: false,
                  type: 'number',
                },
                {
                  title: 'Adaptation OpEx',
                  key: 'adaptationOpex',
                  required: false,
                  type: 'number',
                },
              ]}
              onUploadDone={onUploadDone}
              onConfirmOpen={onCSVConfirmOpen}
              onConfirmClose={onCSVConfirmClose}
              isConfirmOpen={isCSVConfirmOpen}
            />
          </HStack>
        </HStack>
        <VisibleIf condition={containsNegativeFinancials}>
          <NegativeNumbersWarningBox mb="8px" />
        </VisibleIf>
        <VisibleIf condition={!isCSVUploadOpen && !isCSVConfirmOpen}>
          {localFinancials && (
            <FilteredFinancials
              isLoading={loadingFinancials}
              reportingGroups={reportingGroups}
              financials={localFinancials}
              filter={filter}
              selectedFinancials={selectedFinancials}
              onUpdate={(updated: CompanyFinancialResults) => setLocalFinancials(updated)}
              isLocked={cAssessment?.isLocked ?? false}
              isSaving={isSaving}
              saveError={saveError}
            />
          )}
        </VisibleIf>
      </VStack>
    </ContentLayout>
  );
};
