import { HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { AutoResizeTextarea } from 'Atoms';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import { debounce } from 'lodash';
import { AttachmentBox, GetMetricAnswerDocumentationByIdDocument_, NoteHistory } from 'models';
import { AttachmentContainer } from 'Molecules/Attachment';
import { useSaveNote } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import { UserAvatar } from 'Organisms';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { getLastEditedData } from './InputTable/MetricsUtils';

export const MetricDocumentationAttachments = ({
  attachmentBox,
}: {
  attachmentBox: AttachmentBox | undefined;
}) => {
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();

  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      refetch={[GetMetricAnswerDocumentationByIdDocument_]}
      onClose={onAttachmentDrawerClose}
      attachmentBox={attachmentBox}
    >
      <AttachmentContainer
        openAttachmentDrawer={onAttachmentDrawerOpen}
        attachmentBox={attachmentBox}
        refetchQueries={[GetMetricAnswerDocumentationByIdDocument_]}
        showSingleFile={true}
        size="sm"
      />
    </AttachmentDrawer>
  );
};

export const MetricDocumentationNotes = ({
  noteHistory,
}: {
  noteHistory: NoteHistory | undefined;
}) => {
  const [noteHistoryId, setNoteHistoryId] = useState(noteHistory?.id);
  const [inputNote, setInputNote] = useState<string | null>();

  const saveNote = useSaveNote();

  const latestNote = useMemo(() => noteHistory?.notes[0], [noteHistory]);

  useEffect(() => {
    setInputNote(latestNote?.body ?? '');
  }, [latestNote]);

  useEffect(() => setNoteHistoryId(noteHistory?.id), [noteHistory]);

  const debounceSave = useCallback(
    debounce((newValue) => {
      saveNote(newValue ?? '', noteHistory?.id, [GetMetricAnswerDocumentationByIdDocument_]);
    }, 1000),
    [debounce, noteHistory]
  );

  const handleInputChange = (newNote: string | undefined | null) => {
    debounceSave(newNote);
  };

  const lastEdited = getLastEditedData(latestNote?.updatedAt, latestNote?.author);

  return (
    <VStack alignItems="start" spacing="8px">
      <AutoResizeTextarea
        key={noteHistoryId}
        w="183px"
        py="4px"
        maxBlockSize={5}
        minH="80px"
        variant="ghost"
        border="none"
        placeholder="Add notes"
        defaultValue={inputNote ?? ''}
        onChange={(e) => handleInputChange(e.target.value)}
      />
      {latestNote && (
        <HStack spacing="4px">
          <UserAvatar user={lastEdited.user ?? latestNote.author} size="xs" />
          <Typography variant="body">{lastEdited.date}</Typography>
        </HStack>
      )}
    </VStack>
  );
};
