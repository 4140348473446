import { VStack, useDisclosure, HStack, Skeleton } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useGetAggregatedMetrics, AggregatedQualitativeAnswers } from './AggregatedMetrics.hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, IconButton, Infobox } from 'Atoms';
import { HelpIcon } from 'Tokens/Icons/Status';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader } from 'Molecules';
import {
  GetAnswersForMetricsOnCompanyLevelDocument_,
  GetAnswersForMetricsOnGroupLevelDocument_,
  QuestionType_Enum_,
} from 'models';
import { HistogramIcon, TableIcon } from 'Tokens/Icons/Data';
import { FrequencyEnums, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { MetricsTableFilters } from '../MetricsTableFilters';
import { AggregatedMetricsTable } from './AggregatedMetricsTable';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { DownloadIcon, UploadIcon } from 'Tokens/Icons/Function';
import {
  SelectedMetric,
  useGetAggregatedMetricsData,
  DREnums,
  MetricsTableData,
} from 'containers/Esrs';
import { OptOutModal } from '../OptOutModal';
import { useApolloClient } from '@apollo/client';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { NarrativeMetricsTable } from '../InputTable/NarrativeMetricsTable';
import { MetricSidebar } from '../MetricSidebar';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { IconArrowNarrowDown } from '@tabler/icons-react';
import { MetricsAISection } from '../MetricAI';
import { Menu } from 'Molecules/Menu';
import { MetricsExcelUploadModal, useExportEsrsMetricTable } from '../MetricsExcel';
import { GroupOrderOptions, GroupOrderSelector, QuarterSelector } from './AggregatedMetricsUtils';
import { orderBy } from 'lodash';
import { getMetricRefNumber } from '../Metrics.hooks';

export const TableViewSwitcher = ({
  setIsChartsView,
  isChartsView,
}: {
  setIsChartsView: (param: boolean) => void;
  isChartsView: boolean;
}) => (
  <HStack
    spacing="0px"
    w="fit-content"
    border="1px solid"
    borderRadius="8px"
    borderColor="border.decorative"
    height="36px"
  >
    <IconButton
      aria-label="table-icon"
      variant="ghost"
      icon={<TableIcon color={isChartsView ? 'text.muted' : 'text.selected'} />}
      borderRightRadius="0px"
      onClick={() => setIsChartsView(false)}
      bg={isChartsView ? 'none' : 'bg.selected'}
    />
    <IconButton
      aria-label="histogram"
      variant="ghost"
      icon={<HistogramIcon color={isChartsView ? 'text.selected' : 'text.muted'} />}
      borderLeftRadius="0px"
      onClick={() => setIsChartsView(true)}
      bg={isChartsView ? 'bg.selected' : 'none'}
    />
  </HStack>
);

export type GeneratedSummaryStatus =
  | {
      metricRef: string;
      isApproved: boolean;
    }
  | undefined;

export const AggregatedMetrics = ({
  extraHeaderContent,
  isGeneratingAnswers = false,
  withAssociation = false,
  withBorder = false,
  rowData,
  setRowData,
  setMetricSidebarOpen,
}: {
  extraHeaderContent?: React.ReactNode;
  isGeneratingAnswers?: boolean;
  withAssociation?: boolean;
  withBorder?: boolean;
  rowData?: MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] };
  setRowData: (param: MetricsTableData | undefined) => void;
  setMetricSidebarOpen: (val: boolean) => void;
}) => {
  const client = useApolloClient();
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef, drType } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isImportOpen, onOpen: onImportOpen, onClose: onImportClose } = useDisclosure();
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [selectedQuarter, setSelectedQuarter] = useState<TimePeriods>(TimePeriodsEnums.year);
  const [groupOrOrder, setGroupOrOrder] = useState(GroupOrderOptions.byType);
  const [selectedExcelMetric, setSelectedExcelMetric] = useState<MetricsTableData>();
  const exportMetrics = useExportEsrsMetricTable();
  const toast = useToast();

  const [generatedSummaryStatus, setGeneratedSummaryStatus] = useState<GeneratedSummaryStatus>();

  const { company } = useCurrentCompany();
  const [searchParams] = useSearchParams();
  const urlMetricRef = useMemo(() => searchParams.get('metricRef'), [searchParams]);

  const {
    metricDR,
    metrics,
    filteredMetrics,
    companyAssessmentId,
    hasInputSection,
    companyLevelReportingUnitId,
    esrsAssessment,
    isGroup,
    dataLoading,
  } = useGetAggregatedMetricsData(withAssociation);

  const {
    aggregatedMetrics,
    qualitativeMetrics: aggregatedQualitativeMetrics,
    aggregatedMetricsAnswers,
    aggregationQuantitativeMetrics,
    loading,
  } = useGetAggregatedMetrics(esrsAssessmentId, metrics ?? [], standardRef, companyAssessmentId);

  const { title = '', reference = '', description = '' } = metricDR || {};
  const isMetricDisclosure = useMemo(() => drType === DREnums.metric, [drType]);
  const [numberOfRows, setNumberOfRows] = useState<Record<string, number>>({});
  const [isComments, setIsComments] = useState<boolean>(false);
  const isActionOrPolicy = useMemo(
    () => drType === DREnums.action || drType === DREnums.policy,
    [drType]
  );

  const isTarget = useMemo(() => drType === DREnums.target, [drType]);
  const allMetricsYearly = useMemo(
    () =>
      aggregatedMetrics.every(
        (met) => met.metric.materialMetrics[0].frequency === FrequencyEnums.yearly
      ),
    [aggregatedMetrics]
  );

  useEffect(() => {
    setRowData(undefined);
  }, [disclosureRequirementRef]);

  useEffect(() => {
    if (rowData) setMetricSidebarOpen(true);
    else setMetricSidebarOpen(false);
  }, [rowData]);

  const summaryQualitativeMetrics = useMemo(
    () =>
      isGroup
        ? aggregatedQualitativeMetrics.filter(
            (metric) =>
              metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
                ?.dataCollection === DataCollectionLevel.subsidiaries
          ) ?? []
        : aggregatedQualitativeMetrics.filter(
            (metric) =>
              metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
                ?.dataCollection === DataCollectionLevel.reportingUnits
          ) ?? [],
    [aggregatedQualitativeMetrics, companyAssessmentId, isGroup]
  );

  // Data Input - Overview Selection
  const isSomeMetricsOnSubsidiaryLevel = useMemo(
    () =>
      isGroup &&
      aggregationQuantitativeMetrics.some(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.dataCollection === DataCollectionLevel.subsidiaries
      ),
    [isGroup, aggregationQuantitativeMetrics, companyAssessmentId]
  );
  useEffect(() => {
    client.refetchQueries({
      include: [
        GetAnswersForMetricsOnCompanyLevelDocument_,
        ...(isGroup ? [GetAnswersForMetricsOnGroupLevelDocument_] : []),
      ],
    });
  }, [isGroup]);

  // Quarter selection
  const [areMetricsYearly, areMetricsQuarterly] = useMemo(
    () => [
      aggregatedMetrics.every(
        ({ metric }) => metric.materialMetrics[0]?.frequency === FrequencyEnums.yearly
      ) &&
        filteredMetrics.every(
          (metric) =>
            metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
              ?.frequency === FrequencyEnums.yearly
        ),
      aggregatedMetrics.every(
        ({ metric }) => metric.materialMetrics[0]?.frequency === FrequencyEnums.quarterly
      ) &&
        filteredMetrics.every(
          (metric) =>
            metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
              ?.frequency === FrequencyEnums.quarterly
        ),
    ],
    [aggregatedMetrics, filteredMetrics]
  );

  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter(TimePeriodsEnums.year);
    else if (areMetricsQuarterly) setSelectedQuarter(TimePeriodsEnums.q1);
  }, [areMetricsYearly]);

  const [isSelectedMetricYearly, isSelectedMetricQuarterly] = useMemo(() => {
    const materialMetric = selectedExcelMetric?.metric.materialMetrics.find(
      (m) => m.materialStandardId === companyAssessmentId
    );
    return [
      materialMetric?.frequency === FrequencyEnums.yearly ?? false,
      materialMetric?.frequency === FrequencyEnums.quarterly ?? false,
    ];
  }, [selectedExcelMetric]);

  const allFilteredMetrics = useMemo(() => {
    const metricArray: string[] = [];
    const filterMetrics = (metric: MetricsTableData, hidden?: boolean): MetricsTableData => {
      if (metric.subRows) {
        if (metricArray.includes(metric.metric.reference) && metric.parentMetric) {
          return {
            ...metric,
            hidden: hidden,
            subRows: [
              {
                metric: metric.metric,
                referenceToSource: metric.metric?.reference,
                hidden: hidden,
                subRows: metric.subRows.map((subrow) => ({
                  ...subrow,
                  hidden: true,
                  subRows: subrow?.subRows?.map((row) => filterMetrics(row, true)),
                })),
              },
            ],
          };
        }
        metricArray.push(metric.metric.reference);
        const subRows = metric?.subRows?.map((row) => filterMetrics(row, hidden));
        if (subRows) {
          return { ...metric, subRows: subRows, hidden: hidden };
        }
      }
      return { ...metric, hidden: hidden };
    };
    return [...aggregatedMetrics.map((metric) => filterMetrics(metric))];
  }, [aggregatedMetrics]);

  const qualitativeMetrics = useMemo(
    () =>
      filteredMetrics?.filter(
        (metric) =>
          metric.metricType === QuestionType_Enum_.LongText_ ||
          metric.metricType === QuestionType_Enum_.YesNo_ ||
          metric.metricType === QuestionType_Enum_.SingleChoice_ ||
          metric.metricType === QuestionType_Enum_.MultipleChoice_
      ),
    [filteredMetrics]
  );

  const orderedMetrics = useMemo(() => {
    if (groupOrOrder !== GroupOrderOptions.byEsrsOrder) {
      return allFilteredMetrics.filter((met) =>
        selectedQuarter === TimePeriodsEnums.year
          ? true
          : met.metric.materialMetrics[0].frequency === FrequencyEnums.quarterly
      );
    } else {
      const mixedMetrics = [
        ...allFilteredMetrics,
        ...qualitativeMetrics.map((met) => ({
          metric: {
            ...met,
            globalOrder: met.globalOrder,
            order: met.order,
          },
        })),
        ...summaryQualitativeMetrics.map((met) => ({
          metric: {
            ...met,
            globalOrder: met.globalOrder,
            order: met.order,
          },
        })),
      ];
      return orderBy(
        mixedMetrics,
        [
          (metric) => metric.metric.globalOrder === null,
          (metric) => metric.metric.globalOrder,
          (metric) => metric.metric.order,
        ],
        ['asc', 'asc', 'asc']
      ).filter((met) =>
        selectedQuarter === TimePeriodsEnums.year
          ? true
          : met.metric.materialMetrics[0].frequency === FrequencyEnums.quarterly
      );
    }
  }, [groupOrOrder, allFilteredMetrics, selectedQuarter]);

  const isOnlyNarrative = useMemo(
    () => orderedMetrics.every((met) => met.metric.metricType !== QuestionType_Enum_.Decimal_),
    [orderedMetrics]
  );

  const showNarrativeSection = useMemo(
    () =>
      groupOrOrder === GroupOrderOptions.byType &&
      selectedQuarter === TimePeriodsEnums.year &&
      (!!qualitativeMetrics.length || !!summaryQualitativeMetrics.length),
    [groupOrOrder, selectedQuarter, qualitativeMetrics, summaryQualitativeMetrics]
  );

  const showTableFilterBar = useMemo(() => {
    const hasDataOnInputLevel = hasInputSection || !!summaryQualitativeMetrics?.length;
    const enableOverviewSwitchForNonMetricDrs = !isMetricDisclosure
      ? isSomeMetricsOnSubsidiaryLevel
      : true;
    return enableOverviewSwitchForNonMetricDrs && hasDataOnInputLevel;
  }, [
    hasInputSection,
    aggregatedQualitativeMetrics,
    isMetricDisclosure,
    isSomeMetricsOnSubsidiaryLevel,
    summaryQualitativeMetrics,
  ]);

  //Scroll
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(urlMetricRef ?? '');
      if (element && urlMetricRef === element.id) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }, 300);
      }
    }, 500);
  }, [aggregatedMetrics, filteredMetrics, urlMetricRef, allFilteredMetrics]);

  const toggleShowMore = (category: string) => {
    setNumberOfRows((prevStates) => ({
      ...prevStates,
      [category]: (prevStates[category] || 5) + 10,
    }));
  };

  const getTotalRows = (data: MetricsTableData[]): number => {
    let totalRows = data.filter((row) => !row.hidden).length;
    for (const row of data) {
      if (row.subRows && row.subRows.length > 0) {
        totalRows += getTotalRows(row.subRows);
      }
    }
    return totalRows;
  };

  // Reset summary status when rowData changes
  useEffect(() => {
    if (!!generatedSummaryStatus && rowData?.metric.reference !== generatedSummaryStatus.metricRef)
      setGeneratedSummaryStatus(undefined);
  }, [rowData, generatedSummaryStatus]);

  const isLoading = useMemo(() => loading || dataLoading, [loading, dataLoading]);

  if (isLoading) return <Loader />;
  return (
    <HStack w="100%" alignItems="start" spacing="16px">
      <VStack spacing={isMetricDisclosure ? '8px' : '12px'} alignItems="start" w="100%">
        {isTarget ? (
          <></>
        ) : isActionOrPolicy ? (
          <VStack alignItems="start" w="100%">
            {!!qualitativeMetrics.length && (
              <Typography variant="h3">Disclosure Requirements</Typography>
            )}
            {showTableFilterBar && (
              <MetricsTableFilters
                selectedQuarter={selectedQuarter}
                setSelectedQuarter={setSelectedQuarter}
                areMetricsQuarterly={areMetricsQuarterly}
                areMetricsYearly={areMetricsYearly}
              />
            )}
            {extraHeaderContent}
          </VStack>
        ) : (
          <VStack spacing="8px" alignItems="start" w="100%">
            <HStack w="100%" justifyContent="space-between">
              <HStack spacing="6px" alignItems="center" justifyContent="space-between" w="100%">
                <Typography variant="bodyLarge" as="span" color="text.hint">
                  {reference}{' '}
                  <Typography variant="h3" as="span">
                    {title}
                  </Typography>
                </Typography>
                <HStack w="40%" justifyContent="flex-end">
                  <GroupOrderSelector
                    groupOrOrder={groupOrOrder}
                    setGroupOrOrder={setGroupOrOrder}
                  />
                  {!allMetricsYearly && (
                    <QuarterSelector
                      defaultQuarter={selectedQuarter as TimePeriodsEnums}
                      setSelectedQuarter={setSelectedQuarter}
                    />
                  )}
                  <IconButton
                    aria-label="learn more"
                    variant="ghost"
                    icon={<HelpIcon />}
                    size="sm"
                    onClick={() => {
                      setSelectedMetric({
                        reference: reference,
                        title: title,
                        description: description ?? '',
                        isDisclosure: true,
                      });
                      onDrawerOpen();
                    }}
                  />
                </HStack>
              </HStack>
            </HStack>
          </VStack>
        )}

        <VStack
          spacing="40px"
          alignItems="stretch"
          w="100%"
          minH="fit-content"
          mb={isTarget ? '' : '40px'}
        >
          {(!allMetricsYearly || !(drType === DREnums.action || drType == DREnums.target)) && (
            <VStack alignItems="stretch" gap="12px" w="100%">
              {!allMetricsYearly && (
                <Infobox
                  status="neutral"
                  closable={false}
                  w="100%"
                  title={
                    selectedQuarter === TimePeriodsEnums.year
                      ? 'Full year view'
                      : `Quarter view: ${selectedQuarter}`
                  }
                  description={
                    selectedQuarter === TimePeriodsEnums.year
                      ? 'You are looking now at the full year view. Some metrics in this disclosure requirement are collected quarterly — to see data per quarter, switch between views above.'
                      : 'You are looking now at metrics collected quarterly. To see the full year overview and narrative data points, switch to the "Full year" view above.'
                  }
                />
              )}
              {!(drType === DREnums.action || drType == DREnums.target) && (
                <MetricsAISection
                  reportingUnitId={companyLevelReportingUnitId}
                  materialStandardId={companyAssessmentId}
                  disclosureRequirementRef={disclosureRequirementRef ?? ''}
                  allFilteredMetrics={allFilteredMetrics}
                  areMetricsYearly={areMetricsYearly}
                  isCompanyLevel={hasInputSection}
                  isGroup={isGroup}
                  onDrawerOpen={onDrawerOpen}
                  rowData={rowData}
                  setRowData={setRowData}
                  onOpen={onOpen}
                  selectedQuarter={selectedQuarter}
                  selectedMetric={selectedMetric}
                  setSelectedMetric={setSelectedMetric}
                  esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                  aggregatedMetricsAnswers={aggregatedMetricsAnswers}
                  qualitativeMetrics={qualitativeMetrics}
                  summaryQualitativeMetrics={summaryQualitativeMetrics}
                  isPolicy={drType === DREnums.policy}
                />
              )}
            </VStack>
          )}
          {!(groupOrOrder === GroupOrderOptions.byType && isOnlyNarrative) && (
            <VStack alignItems="start" gap="20px" w="100%">
              {groupOrOrder === GroupOrderOptions.byType && (
                <Typography variant="h3">Metrics</Typography>
              )}
              <VStack alignItems="start" gap="40px" w="100%">
                {orderedMetrics?.map((metric) => {
                  if (metric.metric.metricType !== QuestionType_Enum_.Decimal_) {
                    return (
                      <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading} w="100%">
                        <VStack alignItems="start" spacing="6px" w="100%">
                          <LinkAnchor
                            hideIcon
                            id={metric.metric.reference}
                            text={`${metric.metric.title}`}
                            reference={getMetricRefNumber(metric.metric)}
                          />
                          <NarrativeMetricsTable
                            metrics={[metric.metric]}
                            selectedQuarter={selectedQuarter}
                            esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                            rowData={rowData}
                            setRowData={setRowData}
                            isGeneratingAnswers={isGeneratingAnswers}
                            companyReportingUnit={companyLevelReportingUnitId}
                            withBorder={withBorder}
                          />
                        </VStack>
                      </Skeleton>
                    );
                  }
                  const rowsCount = getTotalRows([metric]);
                  const remainingCount =
                    rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5) > 10
                      ? 10
                      : rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5);
                  const showMoreButton = remainingCount > 0;
                  const isTableMetricYearly = metric?.metric.materialMetrics.every(
                    (m) => m.frequency === FrequencyEnums.yearly
                  );
                  const isTableMetricQuarterly = metric?.metric.materialMetrics.every(
                    (m) => m.frequency === FrequencyEnums.quarterly
                  );
                  return (
                    <Skeleton
                      key={metric.metric.reference}
                      isLoaded={isMetricDisclosure ? true : !isLoading}
                      width="100%"
                    >
                      <VStack
                        alignItems="start"
                        id={metric.metric.reference}
                        spacing="6px"
                        w="100%"
                      >
                        <HStack justifyContent="space-between" w="100%">
                          <LinkAnchor
                            hideIcon
                            id={metric.metric.reference}
                            text={`${metric.metric.title}`}
                            reference={getMetricRefNumber(metric.metric)}
                          />

                          <HStack spacing="4px">
                            <Button
                              variant="ghost"
                              leftIcon={<UploadIcon />}
                              onClick={() => {
                                onImportOpen();
                                setSelectedExcelMetric(metric);
                              }}
                            >
                              Import
                            </Button>
                            <Menu
                              size="md"
                              sections={[
                                {
                                  actions: [
                                    {
                                      id: 'download',
                                      title: 'Download Excel file',
                                      variant: 'ghost',
                                      onClick: () => {
                                        toast({
                                          text: 'Downloading file...',
                                          closable: true,
                                          duration: null,
                                        });
                                        if (metric) {
                                          exportMetrics(
                                            [metric],
                                            disclosureRequirementRef ?? '',
                                            company?.name ?? 'Company',
                                            isTableMetricQuarterly,
                                            isTableMetricYearly,
                                            companyAssessmentId,
                                            companyLevelReportingUnitId
                                          );
                                        }
                                      },
                                      leftElement: <DownloadIcon />,
                                    },
                                  ],
                                },
                              ]}
                            />
                          </HStack>
                        </HStack>
                        <AggregatedMetricsTable
                          metrics={[metric]}
                          selectedQuarter={selectedQuarter}
                          setSelectedMetric={setSelectedMetric}
                          onDrawerOpen={onDrawerOpen}
                          areMetricsYearly={areMetricsYearly}
                          isGroup={isGroup}
                          currency={company?.currency ?? ''}
                          companyAssessmentId={companyAssessmentId}
                          onOpen={onOpen}
                          companyStandardId={companyAssessmentId}
                          companyReportingUnit={companyLevelReportingUnitId}
                          isCompanyLevel={hasInputSection}
                          esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                          rowData={rowData}
                          setIsComments={setIsComments}
                          answersData={aggregatedMetricsAnswers}
                          setRowData={setRowData}
                          pageSize={numberOfRows[metric.metric.reference]}
                          filteredMetrics={allFilteredMetrics}
                          setNumberOfRows={setNumberOfRows}
                          isMetricDr={isMetricDisclosure}
                        />
                        {showMoreButton && (
                          <Button
                            mt="2px"
                            variant="ghost"
                            size="sm"
                            onClick={() => toggleShowMore(metric.metric.reference)}
                          >
                            <HStack spacing="8px">
                              <IconArrowNarrowDown size="16px" />
                              <Typography variant="bodyStrong" size="sm">
                                Load {remainingCount} more
                              </Typography>
                            </HStack>
                          </Button>
                        )}
                      </VStack>
                    </Skeleton>
                  );
                })}
              </VStack>
            </VStack>
          )}
          {showNarrativeSection && (
            <VStack alignItems="stretch" gap="20px" w="100%">
              <Typography variant="h3">Narrative data points</Typography>
              {qualitativeMetrics?.length !== 0 && (
                <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading} w="100%">
                  <VStack alignItems="start" spacing="6px" w="100%">
                    <NarrativeMetricsTable
                      metrics={qualitativeMetrics}
                      selectedQuarter={selectedQuarter}
                      esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                      rowData={rowData}
                      setRowData={setRowData}
                      isGeneratingAnswers={isGeneratingAnswers}
                      companyReportingUnit={companyLevelReportingUnitId}
                      withBorder={withBorder}
                    />
                  </VStack>
                </Skeleton>
              )}
              {summaryQualitativeMetrics?.length !== 0 && (
                <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading}>
                  <VStack alignItems="start" spacing="6px">
                    <NarrativeMetricsTable
                      metrics={summaryQualitativeMetrics}
                      selectedQuarter={TimePeriodsEnums.year}
                      esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                      rowData={rowData}
                      setRowData={setRowData}
                      answersData={aggregatedMetricsAnswers}
                      companyReportingUnit={companyLevelReportingUnitId}
                      withBorder={withBorder}
                      generatedSummaryStatus={generatedSummaryStatus}
                    />
                  </VStack>
                </Skeleton>
              )}
            </VStack>
          )}
        </VStack>
        <LearnMoreDrawer
          header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          description={selectedMetric?.description ?? ''}
          customHeader={
            !selectedMetric?.isDisclosure ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            ) : undefined
          }
        />
        <OptOutModal
          isOpen={isOpen}
          onClose={onClose}
          assessmentId={esrsAssessmentId}
          reportingUnitId={companyLevelReportingUnitId}
          metricRef={selectedMetric?.reference ?? ''}
          isCompany={false}
        />
      </VStack>
      {!!rowData && (
        <MetricSidebar
          row={rowData}
          metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
          materialStandardId={companyAssessmentId}
          companyReportingUnit={companyLevelReportingUnitId}
          selectedMetric={selectedMetric}
          esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
          setSelectedMetric={setSelectedMetric}
          onMenuOpen={onOpen}
          selectedQuarter={selectedQuarter}
          setRowData={setRowData}
          isComments={isComments}
          isGroup={isGroup}
          isMetricDr={isMetricDisclosure}
          companyCurrency={company?.currency ?? ''}
          generatedSummaryStatus={generatedSummaryStatus}
          setGeneratedSummaryStatus={setGeneratedSummaryStatus}
        />
      )}

      <Suspense>
        {selectedExcelMetric && (
          <MetricsExcelUploadModal
            isOpen={isImportOpen}
            onClose={onImportClose}
            metrics={[selectedExcelMetric]}
            disclosureRequirement={disclosureRequirementRef ?? ''}
            businessUnit={company?.name ?? 'Company'}
            areTableMetricsQuarterly={isSelectedMetricQuarterly}
            areTableMetricsYearly={isSelectedMetricYearly}
            companyStandardId={companyAssessmentId}
            companyReportingUnitId={companyLevelReportingUnitId}
          />
        )}
      </Suspense>
    </HStack>
  );
};
