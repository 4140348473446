import { HStack, VStack } from '@chakra-ui/react';
import { Checkbox } from 'Atoms';
import { Loader, Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { useGetDatapointValues } from '../QuarterInput';
import { MetricsTableData } from '../..';
import { useEffect, useState } from 'react';
import { TimePeriodsEnums } from '../../../Requirement';

export const AdditionalInfoModal = ({
  isOpen,
  onClose,
  metric,
  companyReportingUnit,
}: {
  isOpen: boolean;
  onClose: () => void;
  metric: MetricsTableData['metric'];
  companyReportingUnit: string | undefined;
}) => {
  const [before, setBefore] = useState(false);
  const [after, setAfter] = useState(false);
  const { onDatapointChange, answer, dataPointPerYear, loading } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnit ?? ''
  );

  useEffect(() => {
    setBefore(dataPointPerYear?.metadata?.before?.isVisible === true);
    setAfter(dataPointPerYear?.metadata?.after?.isVisible === true);
  }, [dataPointPerYear]);

  const onConfirm = () => {
    const data = dataPointPerYear?.metadata;
    const metadata = {
      ...data,
      before: {
        ...(data?.before ?? {}),
        isVisible: before,
      },
      after: {
        ...(data?.after ?? {}),
        isVisible: after,
      },
    };
    onDatapointChange({
      value: dataPointPerYear?.value ?? '',
      factValue: dataPointPerYear?.factValue,
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
      metadata: metadata,
    }).then(() => onClose());
  };

  if (loading) return <Loader />;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Additional information" onConfirm={onConfirm}>
      <VStack alignItems="start" spacing="16px">
        <Typography variant="body">
          To disclose additional information beyond what is covered by the child data points, add
          complementary text to the parent data point either before or after the child data points.
        </Typography>
        <VStack alignItems="start" spacing="12px">
          <Typography variant="bodyStrong">Add complimentary texts:</Typography>
          <HStack spacing="8px">
            <Checkbox isChecked={before} onChange={(e) => setBefore(e.target.checked)} />
            <Typography variant="body">Before child data points</Typography>
          </HStack>
          <HStack spacing="8px">
            <Checkbox isChecked={after} onChange={(e) => setAfter(e.target.checked)} />
            <Typography variant="body">After child data points</Typography>
          </HStack>
        </VStack>
      </VStack>
    </Modal>
  );
};
