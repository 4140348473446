import {
  HStack,
  Circle,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  useColorMode,
} from '@chakra-ui/react';
import { PieChart } from 'react-minimal-pie-chart';
import { colors, Typography } from 'Tokens';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { FrequencyEnums, QUARTERS_FIELDS } from '../../Requirement';
import { useMemo } from 'react';
import { AggregatedMetricsTableData, AggregatedQualitativeAnswers } from '../AggregatedMetrics';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { CalendarIcon } from 'Tokens/Icons/Data';
import { ProgressBar } from 'Atoms';
import { DetailsCard } from '../MetricSidebar/MetricSource';

const useCheckProgress = (
  row: AggregatedMetricsTableData,
  companyStandardId: string,
  companyReportingUnit?: string
) => {
  const isAggregated = useMemo(() => {
    return !!row?.subRows?.length;
  }, [row]);
  const materialMetric = useMemo(() => row.metric.materialMetrics?.[0], [row]);
  const isSubsidiaries = useMemo(
    () => materialMetric?.dataCollection === DataCollectionLevel.subsidiaries,
    [materialMetric]
  );
  const isYearly = useMemo(
    () => materialMetric?.frequency === FrequencyEnums.yearly,
    [materialMetric]
  );
  const dataPointsPerQuarter = useMemo(
    () =>
      Object.entries(row.result ?? {})
        .filter(([key]) => key !== 'Year')
        .map(([key, value]) => ({
          field: key.toUpperCase(),
          value: value,
        })),
    [row]
  );

  if (isAggregated) {
    let total = 0;
    if (!!row.subRows) {
      // This should be fixed asap!!
      row.subRows.forEach((subRow) => {
        total += useCheckProgress(subRow, companyStandardId, companyReportingUnit);
      });
      return Math.floor(total / row.subRows.length);
    }
  }

  if (isSubsidiaries) {
    if (isYearly) {
      if (row.result?.Year !== 0) return 100;
      else return 0;
    }
  }
  const isAssessedYearly = row.result?.Year ? true : false;
  const assessedQuarters = isAggregated
    ? []
    : dataPointsPerQuarter.filter((item) => item.value !== 0);
  return isYearly ? (isAssessedYearly ? 100 : 0) : (assessedQuarters.length * 100) / 4;
};

const CollectedPopover = ({
  row,
  answersData,
  isGroup,
  progress,
}: {
  row: AggregatedMetricsTableData;
  answersData?: AggregatedQualitativeAnswers;
  isGroup: boolean;
  progress: number;
}) => {
  const answerData = answersData?.find((data) => data.metricRef === row.metric?.reference);
  const [isSubsidiaries, isBusinessUnits] = useMemo(
    () => [
      row.metric.materialMetrics?.[0]?.dataCollection === DataCollectionLevel.subsidiaries,
      row.metric.materialMetrics?.[0]?.dataCollection === DataCollectionLevel.reportingUnits,
    ],
    [row]
  );

  return (
    <VStack alignItems="start" justifyContent="start" p={0} spacing="16px">
      <VStack alignItems="start" w="100%" gap="8px">
        <HStack justifyContent="space-between" w="100%">
          <Typography variant="bodyStrong">Data collection progress</Typography>
          <Typography variant="h4">{progress}%</Typography>
        </HStack>
        <ProgressBar completed={progress} />
      </VStack>
      <VStack alignItems="start" w="100%" gap="8px">
        {isSubsidiaries
          ? answerData?.subsidiaries?.map((sub) => (
              <DetailsCard
                title={sub.subsidiary.company.name}
                author={sub.answer?.datapoints?.[0]?.owner?.displayName ?? ''}
                answer={sub.answer?.datapoints?.[0]?.value}
                subsidiary={sub.subsidiary}
                metricRef={answerData.metricRef}
                isGroup={isGroup}
                isNumeric
                choices={sub.answer?.datapoints?.[0]?.datapointChoices.map((c) => c.choice.title)}
              />
            ))
          : isBusinessUnits
            ? answerData?.reportingUnits?.map((ru) => (
                <DetailsCard
                  title={ru.reportingUnit.name}
                  author={ru.answer?.datapoints?.[0]?.owner?.displayName ?? ''}
                  answer={ru.answer?.datapoints?.[0]?.value}
                  metricRef={answerData.metricRef}
                  reportingUnitId={ru.reportingUnit.id}
                  isGroup={isGroup}
                  isNumeric
                  choices={ru.answer?.datapoints?.[0]?.datapointChoices.map((c) => c.choice.title)}
                />
              ))
            : QUARTERS_FIELDS.map((quarter) => (
                <HStack justifyContent="space-between" w="100%">
                  <HStack>
                    <CalendarIcon />
                    <Typography variant="body" color="text.muted">
                      {quarter}
                    </Typography>
                  </HStack>
                  {row.result?.[quarter] ? (
                    <Typography variant="body" color="text.muted">
                      {parseFloat(row.result?.[quarter].toFixed(2))}
                    </Typography>
                  ) : (
                    <Typography variant="body" color="text.hint">
                      N/A
                    </Typography>
                  )}
                </HStack>
              ))}
      </VStack>
    </VStack>
  );
};

export const MetricProgress = ({
  row,
  companyStandardId,
  answersData,
  companyReportingUnit,
  isGroup,
}: {
  row: MetricsTableData;
  companyStandardId: string;
  isGroup: boolean;
  answersData?: AggregatedQualitativeAnswers;
  companyReportingUnit?: string;
}) => {
  const progress = useCheckProgress(row, companyStandardId, companyReportingUnit);
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack>
          <Circle size="16px" alignItems="center">
            <PieChart
              lineWidth={32}
              data={[
                {
                  title: 'Collected',
                  value: progress,
                  color: colors['bg.progress'][color],
                },
                {
                  title: 'Missing',
                  value: 100 - progress,
                  color: colors['bg.unknown'][color],
                },
              ]}
            />
          </Circle>
          <Typography variant="body" cursor="default">
            {Number.isNaN(progress) ? 0 : progress}%
          </Typography>
        </HStack>
      </PopoverTrigger>
      <PopoverContent
        w="326px"
        border="none"
        boxShadow=" 0px 0px 24px -2px #0F0F2E1F"
        borderRadius="10px"
      >
        <PopoverBody p="16px">
          <CollectedPopover
            row={row}
            answersData={answersData}
            isGroup={isGroup}
            progress={progress}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
