import { HStack, ToastId } from '@chakra-ui/react';
import { NumberInput, NumberInputProps, Tooltip, VisibleIf } from 'Atoms';
import { Typography } from 'Tokens';
import { LoaderIcon, WarningIcon } from 'Tokens/Icons/Status';
import { useEffect, useMemo, useState, memo, useCallback } from 'react';
import { setTimeoutInHook, useDebounce, useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';

const MAX_ALLOWED_NUMBER = 900000000000000;
const MIN_ALLOWED_NUMBER = 0;

const validateNumber = (value: number, onMaxNumberError?: () => void) => {
  const max = MAX_ALLOWED_NUMBER;
  const min = MIN_ALLOWED_NUMBER;
  let newVal = value;

  if (newVal > MAX_ALLOWED_NUMBER) {
    onMaxNumberError?.();
    newVal = max;
  }
  if ((min && newVal < min) || Number.isNaN(newVal)) {
    newVal = min ?? 0;
  }

  return newVal;
};

export const NumberInputWithError = memo(
  ({
    value,
    onChange,
    debounceTimeout = 750,
    onHasErrorChange = () => {},
    retriesBeforeError = 2,
    disabledMessage,
    ...props
  }: NumberInputProps & {
    disabledMessage?: string;
    retriesBeforeError?: number;
    debounceTimeout?: number;
    onHasErrorChange?: (hasError: boolean) => void;
  }) => {
    const [localValue, setLocalValue] = useState<number>(value ? Number(value) : 0);
    const [isSaved, setIsSaved] = useState<boolean>(true);

    const debouncedValue = useDebounce(localValue, debounceTimeout);

    const [retries, setRetries] = useState<number>(0);
    const [hasError, setHasError] = useState<boolean>(false);

    const { t } = useTranslation(['bunits', 'common']);
    const toast = useToast();

    const [prevToastId, setPrevToastId] = useState<ToastId | undefined>(undefined);

    const onMaxNumberError = useCallback(() => {
      const id = toast({
        text: t('bUnits:financials.maxAllowed', { maxValue: MAX_ALLOWED_NUMBER }),
        variant: 'danger',
        duration: 5000,
        prevId: prevToastId,
      });
      setPrevToastId(id);
    }, [prevToastId, setPrevToastId]);

    const onValueChange = (newValue: number) => {
      const newVal = validateNumber(newValue, onMaxNumberError);
      setLocalValue(newVal);
      setIsSaved(false);
    };

    useEffect(() => {
      onHasErrorChange(hasError);
    }, [hasError, onHasErrorChange]);

    useEffect(() => {
      if (debouncedValue !== value && debouncedValue === localValue && !hasError && !isSaved) {
        setIsSaved(true);
        onChange(debouncedValue);
        return;
      }

      if (debouncedValue !== value && debouncedValue !== localValue) {
        return;
      }

      if (debouncedValue !== value && debouncedValue === localValue && !hasError && isSaved) {
        if (retries < retriesBeforeError) {
          return setTimeoutInHook(() => {
            setRetries(retries + 1);
            onChange(debouncedValue);
          }, 500);
        }
        setHasError(true);
        return;
      }

      if (debouncedValue === value && debouncedValue === localValue) {
        setHasError(false);
        setIsSaved(true);
        return;
      }
    }, [debouncedValue, value, localValue, hasError, retries, retriesBeforeError, isSaved]);

    const unit = useMemo(
      () => (hasError ? <WarningIcon color="text.warning" /> : props.unit),
      [hasError, props.unit]
    );

    const tooltipMessage = props.isDisabled ? disabledMessage : '';

    return (
      <>
        <Tooltip label={tooltipMessage}>
          <NumberInput
            inputFieldProps={{
              borderColor: !!hasError ? 'text.warning' : undefined,
            }}
            unit={unit}
            size="sm"
            minWidth="116px"
            {...props}
            value={localValue}
            onChange={onValueChange}
          />
        </Tooltip>
        {/* Not used for now, do saving state on top level */}
        <VisibleIf condition={false}>
          <HStack gap="2px" mt="1px">
            <LoaderIcon boxSize="12px" />
            <Typography textAlign="left" variant="detail" color="text.muted">
              Saving...
            </Typography>
          </HStack>
        </VisibleIf>
        <VisibleIf condition={hasError}>
          <HStack gap="2px" mt="1px" width="100%">
            <Typography textAlign="left" variant="detail" color="text.muted">
              Not saved.
            </Typography>
            <Typography
              cursor={'pointer'}
              textAlign="left"
              variant="detail"
              color="text.selected"
              _hover={{ textDecoration: 'underline' }}
              onClick={() => onChange(localValue)}
            >
              Try again
            </Typography>
          </HStack>
        </VisibleIf>
      </>
    );
  }
);
