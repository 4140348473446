import { Box, HStack } from '@chakra-ui/react';
import { ColumnDefResolved } from '@tanstack/react-table';
import { Avatar, Button, IconButton, Tooltip, TruncatableText } from 'Atoms';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import { Typography } from 'Tokens';
import {
  ArrowCornerDownRight,
  ArrowNarrowRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from 'Tokens/Icons/Direction';
import { AIIcon } from 'Tokens/Icons/Function';
import { MetricsTableData, SelectedMetric, areArraysOfObjectsEqual } from 'containers/Esrs';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FrequencyEnums, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
} from './AggregatedMetrics.hooks';
import { showResult, showResultAsPercentage } from './calculations';
import {
  AnswerApprovalCell,
  MetricRowTitle,
  getLastEditedData,
  searchForParentMetric,
} from '../InputTable/MetricsUtils';
import { isEqual } from 'lodash';
import { User } from 'models';
import { GeneratedAnswer } from '../MetricAI';
import { MetricMdrmStatus } from '../InputTable/MetricMDRMStatus';
import { MetricOwnerAvatar } from '../InputTable/MetricOwnerSelect';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { QuarterInput } from '../InputTable/QuarterInput';
import { CommentsCounter } from './AggregatedMetricsUtils';
import { MetricProgress } from '../InputTable/MetricProgress';
import { HelpTooltip } from 'Molecules';
import { getMetricRefNumber } from '../Metrics.hooks';
import { LastEditedBy } from '../InputTable/LastEditedBy';
import { MetricsAttachmentsLabel } from '../InputTable/MetricAttachmentsLabel';
import { MetricYear } from '../InputTable/MetricYear';
import { MetricCheckboxSelector } from '../InputTable/MetricCheckboxSelector';

export const QuarterField = ({
  frequency,
  value,
  selectedQuarter,
  showAsPercentage,
  allMetrics,
  currentMetric,
}: {
  frequency: string;
  value: number | string;
  selectedQuarter: TimePeriods;
  showAsPercentage?: boolean;
  allMetrics: AggregatedMetricsTableData[];
  currentMetric: AggregatedMetricsTableData;
}) => {
  const isYearly = useMemo(() => frequency === FrequencyEnums.yearly, [frequency]);

  const tags = useMemo(() => {
    return currentMetric.tags ?? [];
  }, [currentMetric]);

  const percentageTotal = useMemo(() => {
    if (currentMetric.tags?.length) {
      const parentMetric = allMetrics?.find(
        (m) => m.metric.reference === currentMetric.metric.reference && !m.tags?.length
      );
      return showResultAsPercentage(value, parentMetric?.result?.[selectedQuarter] ?? 0);
    }

    return showResultAsPercentage(100, 100);
  }, [allMetrics, currentMetric]);

  if (isYearly && selectedQuarter !== TimePeriodsEnums.year)
    return (
      <Typography variant="body" color="text.hint">
        N/A
      </Typography>
    );

  if (showAsPercentage) {
    return (
      <Typography variant="body">
        {showResult(value)}

        <Tooltip
          label={`${currentMetric.metric.title} ${tags.length ? ' by ' : ''} ${tags.map(
            (x) => x.tagValue
          )}: ${percentageTotal.replace('(', '').replace(')', '')}`}
          cursor="pointer"
        >
          <span style={{ cursor: 'pointer', fontSize: '14px', marginLeft: '4px' }}>
            {percentageTotal}
          </span>
        </Tooltip>
      </Typography>
    );
  }
  return <Typography variant="body">{showResult(value)}</Typography>;
};

export const AggregatedMetricsTable = ({
  metrics,
  selectedQuarter,
  onDrawerOpen,
  setSelectedMetric,
  isOverview = false,
  areMetricsYearly,
  isGroup,
  currency,
  companyAssessmentId,
  onOpen,
  companyStandardId,
  companyReportingUnit,
  esrsAssessmentProjectLeader,
  rowData,
  setRowData,
  pageSize = 5,
  filteredMetrics,
  setNumberOfRows,
  isMetricDr,
  selectedRows,
  setSelectedRows,
  isAI = false,
  answersData,
  setIsComments,
  generatedAnswers,
  setGeneratedAnswers,
  populateQuantitativeAnswers,
  checkboxesDisabled = true,
  isBusinessUnit,
}: {
  metrics: AggregatedMetricsTableData[];
  selectedQuarter: TimePeriods;
  onDrawerOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  isOverview?: boolean;
  areMetricsYearly: boolean;
  isGroup: boolean;
  currency: string;
  answersData?: AggregatedQualitativeAnswers;
  companyAssessmentId: string;
  onOpen: () => void;
  companyStandardId: string;
  companyReportingUnit?: string;
  isCompanyLevel?: boolean;
  esrsAssessmentProjectLeader?: Partial<User>;
  rowData?: MetricsTableData;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  pageSize?: number;
  filteredMetrics?: MetricsTableData[];
  setNumberOfRows: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  isMetricDr: boolean;
  selectedRows?: MetricsTableData[];
  setSelectedRows?: Dispatch<SetStateAction<MetricsTableData[]>>;
  isAI?: boolean;
  setIsComments: (val: boolean) => void;
  generatedAnswers?: GeneratedAnswer[];
  setGeneratedAnswers?: Dispatch<SetStateAction<GeneratedAnswer[]>>;
  populateQuantitativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => void;
  checkboxesDisabled?: boolean;
  isBusinessUnit?: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');

  const showProgress = useMemo(() => {
    const dataCollection = metrics[0].metric.materialMetrics[0].dataCollection;
    if (isBusinessUnit)
      return metrics[0].metric.materialMetrics[0].frequency === FrequencyEnums.quarterly;
    return (
      (dataCollection === DataCollectionLevel.subsidiaries && isGroup) ||
      (dataCollection === DataCollectionLevel.reportingUnits && !isGroup) ||
      metrics[0].metric.materialMetrics[0].frequency === FrequencyEnums.quarterly
    );
  }, [metrics]);

  const progressLabel = useMemo(() => {
    return metrics[0].metric.materialMetrics[0].dataCollection ===
      DataCollectionLevel.subsidiaries && isGroup
      ? 'Subsidiaries'
      : metrics[0].metric.materialMetrics[0].dataCollection === DataCollectionLevel.reportingUnits
        ? 'Business units'
        : 'Quarters';
  }, [metrics]);

  const progressTooltip = useMemo(() => {
    const dataCollection = metrics[0].metric.materialMetrics[0].dataCollection;
    if (dataCollection === DataCollectionLevel.subsidiaries && isGroup) {
      return 'This data point is collected from subsidiaries. The progress indicates whether all subsidiaries have added their answers.';
    } else if (dataCollection === DataCollectionLevel.reportingUnits) {
      return 'This data point is collected from business units. The progress indicates whether all business units have added their answers.';
    }
    return 'This data point is collected quarterly. The progress indicates whether data is collected from all quarters or only a few.';
  }, [metrics]);

  const goToSource = (ref: string) => {
    const refElement = document.getElementById(ref);
    if (refElement) {
      setTimeout(() => {
        refElement.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        });
      });
    } else {
      const mainParent = searchForParentMetric(filteredMetrics ?? [], ref);
      const element = document.getElementById(mainParent?.metric.reference ?? '');
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
          });
        });
      }
      // expand main parent
      setNumberOfRows((prevStates) => ({
        ...prevStates,
        [mainParent?.metric.reference ?? '']:
          (prevStates[mainParent?.metric.reference ?? ''] || 5) + 100,
      }));
    }
  };

  const handleRowClick = (row: MetricsTableData, isComments: boolean) => {
    if (!row.referenceToSource) {
      if (openDrawer && !!urlDatapointId) navigate(pathname.split('?')[0]);
      if (
        isEqual(
          {
            metric: row?.metric,
            sourceData: answersData?.find((ans) => ans.metricRef === row?.metric.reference),
          },
          rowData
        )
      ) {
        setRowData(undefined);
      } else {
        setRowData({
          metric: row.metric,
          sourceData: answersData?.find((ans) => ans.metricRef === row.metric.reference),
        });
        if (isComments) {
          setIsComments(true);
        } else setIsComments(false);
      }
    }
  };

  const nestedColumns: ColumnDefResolved<TableData<AggregatedMetricsTableData>, any>[] | undefined =
    useMemo(
      () => [
        {
          header: '',
          meta: {
            width: '32px',
            padding: '16px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'selectedRows',
          cell: ({ row }) =>
            selectedRows &&
            setSelectedRows && (
              <MetricCheckboxSelector
                row={row.original}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                isDisabled={checkboxesDisabled}
              />
            ),
        },
        {
          header: 'Data point',
          meta: {
            width: '30%',
          },
          accessorKey: 'metric',
          cell: ({ row }) => {
            return row.original.referenceToSource ? (
              <Box pl={`${row.depth * 24 + 44}px`} position="relative">
                <HStack spacing="4px" position="absolute" top="50%" transform="translateY(-50%)">
                  <ArrowCornerDownRight color="text.default" />
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => goToSource(row.original.referenceToSource ?? '')}
                  >
                    Go to sub-metrics
                  </Button>
                  <HelpTooltip
                    placement="bottom"
                    label={
                      'This metric is calculated using several sub-metrics that have been previously reported. For details, go to sub-metrics'
                    }
                  />
                </HStack>
              </Box>
            ) : (
              <HStack
                spacing="8px"
                id={
                  !row.subRows.some((subrow) => subrow.original.referenceToSource)
                    ? row.original.metric.reference
                    : undefined
                }
              >
                {row.getCanExpand() && (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <IconButton
                      variant={'ghost'}
                      size="md"
                      onClick={row.getToggleExpandedHandler()}
                      aria-label="expand"
                      icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    />
                  </Box>
                )}
                <MetricRowTitle
                  companyStandardId={companyStandardId}
                  rowData={rowData}
                  row={row}
                  onClick={() => handleRowClick(row.original, false)}
                />
              </HStack>
            );
          },
        },
        {
          header: 'Ref.',
          meta: {
            width: '88px',
          },
          accessorKey: 'unit',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <TruncatableText text={getMetricRefNumber(row.original.metric)} />
            ),
        },
        {
          header: selectedQuarter !== TimePeriodsEnums.year ? selectedQuarter : "Year's total",
          meta: {
            width: !showProgress && !isMetricDr ? '38%' : showProgress ? '20%' : '30%',
          },
          accessorKey: 'yearly',
          cell: ({ row }) => {
            const materialMetric = row.original.metric.materialMetrics?.find(
              (mm) => mm.materialStandardId === companyAssessmentId
            );
            const showInput =
              isBusinessUnit ||
              (((materialMetric?.dataCollection === DataCollectionLevel.group && isGroup) ||
                materialMetric?.dataCollection === DataCollectionLevel.company) &&
                !(
                  materialMetric.frequency === FrequencyEnums.quarterly &&
                  selectedQuarter === TimePeriodsEnums.year
                ));
            return (
              !row.original.referenceToSource &&
              (showInput ? (
                <QuarterInput
                  row={row.original}
                  selectedQuarter={selectedQuarter}
                  companyStandardId={companyStandardId}
                  companyReportingUnit={companyReportingUnit}
                  assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                  setRowData={setRowData}
                  nestedMetrics={metrics}
                />
              ) : (
                <QuarterField
                  frequency={materialMetric?.frequency ?? 'yearly'}
                  value={row.original.result?.[selectedQuarter] ?? 0}
                  selectedQuarter={selectedQuarter}
                  allMetrics={metrics}
                  currentMetric={row.original}
                  showAsPercentage={row.original.metric?.showAsPercentage ?? false}
                />
              ))
            );
          },
        },
        {
          header: 'Unit',
          meta: {
            width: '88px',
          },
          accessorKey: 'unit',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <TruncatableText
                text={mapUnitToCompanyCurrency(
                  row.original.metric.unitOfMeasurement ?? 'NA',
                  currency
                )}
              />
            ),
        },
        {
          header: 'Answer',
          accessorKey: 'AIAnswer',
          meta: {
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );

            return (
              !row.original.referenceToSource && (
                <MetricYear
                  row={row.original}
                  companyStandardId={companyStandardId}
                  companyReportingUnit={companyReportingUnit}
                  nestedMetrics={metrics}
                  generatedAnswer={generatedAnswer}
                  isAI={isAI}
                />
              )
            );
          },
        },
        {
          header: 'Progress',
          meta: {
            width: '84px',
            subtitle: progressLabel,
            verticalAlign: 'center',
            tooltip: progressTooltip,
          },
          accessorKey: 'progress',
          cell: ({ row }) =>
            row.original &&
            !row.original.referenceToSource && (
              <MetricProgress
                row={row.original}
                answersData={answersData}
                isGroup={isGroup}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
              />
            ),
        },
        {
          header: 'MDR-M',
          meta: {
            width: '68px',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'mdr-m',
          cell: ({ row }) =>
            row.original &&
            !row.original.referenceToSource && (
              <MetricMdrmStatus
                row={row.original}
                companyReportingUnit={companyReportingUnit}
                selectedQuarter={selectedQuarter}
                materialStandardId={companyStandardId}
              />
            ),
        },
        {
          header: 'Source',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'source',
          cell: ({ row }) => {
            return (
              <MetricsAttachmentsLabel
                row={row.original}
                companyReportingUnitId={companyReportingUnit}
              />
            );
          },
        },
        {
          header: 'Last edited',
          accessorKey: 'lastEdited',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );
            const isAwaitingApproval =
              generatedAnswer?.answer &&
              generatedAnswer?.status === null &&
              generatedAnswer?.answer !== 'NA';

            return (
              !row.original.referenceToSource &&
              (isAwaitingApproval ? (
                <HStack spacing="4px">
                  <Avatar name="AI" size="xs" />
                  <Typography variant="body">{getLastEditedData(new Date()).date}</Typography>
                </HStack>
              ) : (
                <LastEditedBy
                  row={row.original}
                  companyStandardId={companyStandardId}
                  selectedQuarter={selectedQuarter}
                  reportingUnitId={companyReportingUnit ? companyReportingUnit : undefined}
                />
              ))
            );
          },
        },
        {
          header: 'Owner',
          meta: {
            width: '54px',
            verticalAlign: 'center',
          },
          accessorKey: 'ownerId',
          cell: ({ row }) =>
            row.original &&
            !row.original.referenceToSource && (
              <MetricOwnerAvatar
                row={row.original}
                selectedQuarter={selectedQuarter}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeader={esrsAssessmentProjectLeader}
              />
            ),
        },
        {
          header: '',
          meta: {
            width: '6%',
            padding: '6px 8px',
          },
          accessorKey: 'actions',
          cell: ({ row }) => {
            return (
              row.original &&
              !row.original.referenceToSource && (
                <HStack gap="2px">
                  <CommentsCounter
                    row={row.original}
                    selectedQuarter={selectedQuarter}
                    companyReportingUnit={companyReportingUnit}
                    onClick={() => handleRowClick(row.original, true)}
                  />
                  <IconButton
                    className="metricArrow"
                    aria-label="side-bar"
                    size="sm"
                    variant="ghost"
                    icon={<ArrowNarrowRightIcon />}
                    onClick={() => handleRowClick(row.original, false)}
                  />
                </HStack>
              )
            );
          },
        },
        {
          // @ts-ignore
          header: () => {
            return (
              <HStack spacing="8px">
                <AIIcon />
                <Typography variant="bodyStrong">Approve</Typography>
              </HStack>
            );
          },
          meta: {
            padding: '10px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'ai-approval',
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );

            const isGeneratedAnswerValid =
              generatedAnswer?.answer && generatedAnswer.answer !== 'NA';

            return (
              !row.original.referenceToSource &&
              isGeneratedAnswerValid && (
                <AnswerApprovalCell
                  row={row.original}
                  populateQuantitativeAnswers={populateQuantitativeAnswers}
                  generatedAnswers={generatedAnswers ?? []}
                  setGeneratedAnswers={setGeneratedAnswers}
                  companyReportingUnitId={companyReportingUnit}
                  assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                />
              )
            );
          },
        },
      ],
      [
        selectedQuarter,
        onDrawerOpen,
        onOpen,
        setSelectedMetric,
        isOverview,
        areMetricsYearly,
        checkboxesDisabled,
        selectedRows,
        generatedAnswers,
        rowData,
      ]
    );

  const filteredColumns = useMemo(() => {
    return nestedColumns?.filter((col) => {
      if (!showProgress && col.accessorKey === 'progress') return false;
      if (!(isAI && selectedRows && setSelectedRows) && col.accessorKey === 'selectedRows')
        return false;
      if (
        isAI &&
        (col.accessorKey === 'unit' ||
          col.accessorKey === 'progress' ||
          col.accessorKey === 'yearly' ||
          col.accessorKey === 'mdr-m')
      )
        return false;
      if (
        !isAI &&
        (col.accessorKey === 'AIAnswer' ||
          col.accessorKey === 'source' ||
          col.accessorKey === 'lastEdited')
      )
        return false;
      if (!(isAI && !!generatedAnswers?.length) && col.accessorKey === 'ai-approval') return false;
      if (!isMetricDr && col.accessorKey === 'mdr-m') return false;
      return true;
    });
  }, [
    nestedColumns,
    selectedQuarter,
    showProgress,
    isAI,
    selectedRows,
    setSelectedRows,
    generatedAnswers,
    isMetricDr,
  ]);

  return (
    <NestedTable<AggregatedMetricsTableData>
      columns={filteredColumns}
      data={metrics}
      expanded={true}
      pageSize={pageSize}
      headerVariant="detailStrong"
      rowProps={(row) => {
        const generatedAnswer = generatedAnswers?.find(
          (a) => a.metricRef === row?.metric.reference && areArraysOfObjectsEqual(a.tags, row?.tags)
        )?.answer;

        const getBackgroundColor = () => {
          if (
            isEqual(
              {
                metric: row?.metric,
                sourceData: answersData?.find((ans) => ans.metricRef === row?.metric.reference),
              },
              rowData
            )
          ) {
            return 'bg.hover';
          }
          if (generatedAnswer) {
            if (generatedAnswer === 'NA') {
              return 'bg.warning';
            }
            return 'bg.selected';
          }
          return '';
        };

        return {
          bg: getBackgroundColor(),
          transition: '0.1s',
          _hover: {
            transition: '0.15s ease-in-out',
            bg: 'bg.hover',
            '&:hover .metricTitle': {
              textDecoration: 'underline',
              textDecorationColor: 'text.hint',
              textUnderlineOffset: '2px',
            },
            '&:hover .metricArrow': {
              bg: 'bg.hover',
            },
          },
        };
      }}
    />
  );
};
