import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TimePeriods } from '../../Requirement';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { QuestionType_Enum_, User } from 'models';
import {
  Box,
  Table as ChakraTable,
  Checkbox,
  HStack,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { LongTextMetricInput } from '../LongTextMetricInput';
import { MetricOwnerAvatar } from './MetricOwnerSelect';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { isEqual } from 'lodash';
import { Avatar, Button, IconButton, TruncatableText } from 'Atoms';
import { AggregatedQualitativeAnswers, GeneratedSummaryStatus } from '../AggregatedMetrics';
import { BooleanMetricInput } from '../BooleanMetricInput';
import { LockedIcon, AIIcon, AddIcon } from 'Tokens/Icons/Function';
import { flattenNarrativeMetrics } from '../Metrics.utils';
import { AssessableMetrics } from '../Metrics';
import { GeneratedAnswer } from '../MetricAI';
import { LastEditedBy } from './LastEditedBy';
import { getLastEditedData, AnswerApprovalCell } from './MetricsUtils';
import { MetricsAttachmentsLabel } from './MetricAttachmentsLabel';
import { ChoiceMetricInput } from './ChoiceMetricInput';
import { getMetricRefNumber } from '../Metrics.hooks';
import { AnswersApprovalWarningModal } from '../MetricAI/AIUtils';
import { AdditionalInfoModal } from './AdditionalInformation/AdditionalInfoModal';
import { AdditionalInfoRow } from './AdditionalInformation/AdditionalInfoRow';

export const NarrativeMetricsTable = ({
  metrics,
  selectedQuarter,
  companyReportingUnit,
  esrsAssessmentProjectLeader,
  isGeneratingAnswers = false,
  rowData,
  setRowData,
  answersData,
  isReadOnly = false,
  withBorder = false,
  isForBooleanMetrics = false,
  isAI = false,
  selectedRows,
  setSelectedRows,
  populateNarrativeAnswers,
  generatedAnswers,
  setGeneratedAnswers,
  checkboxesDisabled = true,
  generatedSummaryStatus,
}: {
  metrics: MetricsTableData['metric'][];
  selectedQuarter: TimePeriods;
  companyReportingUnit?: string;
  esrsAssessmentProjectLeader?: Partial<User>;
  isGeneratingAnswers?: boolean;
  rowData?: MetricsTableData;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  answersData?: AggregatedQualitativeAnswers;
  isReadOnly?: boolean;
  withBorder?: boolean;
  isForBooleanMetrics?: boolean;
  isAI?: boolean;
  selectedRows?: AssessableMetrics;
  setSelectedRows?: Dispatch<SetStateAction<AssessableMetrics>>;
  populateNarrativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: AssessableMetrics
  ) => void;
  generatedAnswers?: GeneratedAnswer[];
  setGeneratedAnswers?: Dispatch<SetStateAction<GeneratedAnswer[]>>;
  checkboxesDisabled?: boolean;
  generatedSummaryStatus?: GeneratedSummaryStatus;
}) => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');
  const [selectedMetricBox, setSelectedMetricBox] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const [booleanMetricAnswers, setBooleanMetricAnswers] = useState<{
    [key: string]: boolean | null;
  }>();
  const [selectedMetric, setSelectedMetric] = useState<MetricsTableData['metric']>();
  const [hasAdditionalInfo, setHasAdditionalInfo] = useState<{
    [key: string]: boolean;
  }>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const theadStyle = {
    letterSpacing: 'normal',
    borderColor: 'border.decorative',
    px: '8px',
    height: '48px',
  };
  const bodyRef = useRef(null);

  const {
    isOpen: isApprovalWarningModalOpen,
    onOpen: onApprovalWarningModalOpen,
    onClose: onApprovalWarningModalClose,
  } = useDisclosure();

  useEffect(() => {
    const handleDeselect = (event: MouseEvent) => {
      if (bodyRef.current && !(bodyRef.current as HTMLElement).contains(event.target as Node)) {
        setSelectedRow('');
        setSelectedMetricBox('');
      }
    };
    document.addEventListener('click', handleDeselect);
    return () => {
      document.addEventListener('click', handleDeselect);
    };
  }, []);

  const flattenedMetrics = useMemo(() => flattenNarrativeMetrics(metrics), [metrics]);

  const allMetricsChecked = useMemo(
    () =>
      flattenedMetrics.every((metric) =>
        selectedRows?.some((row) => row.reference === metric.reference)
      ),
    [selectedRows, flattenedMetrics]
  );

  const someMetricsChecked = useMemo(
    () =>
      !allMetricsChecked &&
      flattenedMetrics.some((metric) =>
        selectedRows?.some((row) => row.reference === metric.reference)
      ),
    [selectedRows, flattenedMetrics, allMetricsChecked]
  );

  const handleCheckAll = useCallback(() => {
    if (allMetricsChecked) {
      setSelectedRows?.([]);
    } else {
      setSelectedRows?.(flattenedMetrics);
    }
  }, [allMetricsChecked, flattenedMetrics, setSelectedRows]);

  const mapMetrics = (
    metric: MetricsTableData['metric'],
    padding: number,
    metricId: string,
    borderTop: boolean,
    answers?: AggregatedQualitativeAnswers
  ): React.ReactNode => {
    const answerData = answers?.find((data) => data.metricRef === metric?.reference);
    const isBooleanMetric = metric?.metricType === QuestionType_Enum_.YesNo_;
    const hasChoice =
      metric?.metricType === QuestionType_Enum_.SingleChoice_ ||
      metric?.metricType === QuestionType_Enum_.MultipleChoice_;
    const canInputAnswer = !metric?.childrenMetrics?.length || isBooleanMetric;

    const areChildrenUnlocked =
      booleanMetricAnswers?.[metric?.reference ?? ''] === metric?.unlockCondition;
    const areChildrenLocked =
      metric?.unlockCondition !== null && !areChildrenUnlocked && isBooleanMetric;

    if (metric) {
      const handleRowClick = () => {
        setSelectedMetricBox(metricId);
        setSelectedRow(metric.reference);
        if (openDrawer && !!hash) navigate(pathname.split('?')[0]);

        if (generatedSummaryStatus?.isApproved === false) {
          onApprovalWarningModalOpen();
        } else {
          if (isEqual(rowData?.metric, metric)) {
            setRowData(undefined);
          } else
            setRowData(
              !!answerData?.reportingUnits?.length || !!answerData?.subsidiaries?.length
                ? { metric: metric as MetricsTableData['metric'], sourceData: answerData }
                : { metric: metric as MetricsTableData['metric'] }
            );
        }
      };

      const generatedAnswer = generatedAnswers?.find((a) => a.metricRef === metric.reference);

      const isAwaitingApproval =
        generatedAnswer?.answer &&
        generatedAnswer?.answer !== '-' &&
        generatedAnswer?.status === null;

      const isParent = metric.childrenMetrics.length > 0;

      const isChecked = isParent
        ? metric.childrenMetrics.every((c) =>
            selectedRows?.some((r) => r.reference === c.childMetric?.reference)
          )
        : selectedRows?.some((row) => row.reference === metric.reference) ?? false;

      const isIntermediate =
        isParent && !isChecked
          ? metric.childrenMetrics.some((c) =>
              selectedRows?.some((r) => r.reference === c.childMetric?.reference)
            )
          : false;

      const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isParent) {
          if (e.target.checked) {
            const childrenMetrics = (metric.childrenMetrics ?? []).map(
              (c) => c.childMetric
            ) as AssessableMetrics;
            setSelectedRows?.([...(selectedRows ?? []), ...childrenMetrics]);
          } else {
            const childrenReferences = (metric.childrenMetrics ?? []).map(
              (c) => c.childMetric?.reference
            );
            setSelectedRows?.(
              (selectedRows ?? []).filter((r) => !childrenReferences.includes(r.reference))
            );
          }
        } else {
          if (e.target.checked) {
            setSelectedRows?.([...(selectedRows ?? []), metric as AssessableMetrics[number]]);
          } else {
            setSelectedRows?.((selectedRows ?? []).filter((r) => r.reference !== metric.reference));
          }
        }
      };

      const getRowBackgroundColor = () => {
        if (selectedMetricBox === metricId) {
          return 'bg.hover';
        }
        if (selectedRow === metric.reference) {
          return 'bg.selected.muted';
        }
        if (generatedAnswer) {
          return generatedAnswer.answer === '-' ? 'bg.warning' : 'bg.selected';
        }
        return 'none';
      };

      return (
        <>
          <Tr
            borderTop={borderTop ? '1px solid' : 'none'}
            borderColor="border.decorative"
            _hover={{
              bg: isReadOnly && !isAI ? '' : 'bg.hover',
              '&:hover .metricTitle': {
                textDecoration: 'underline',
                textDecorationColor: 'text.hint',
                textUnderlineOffset: '2px',
                transition: '0.15s',
              },
              '&:hover .metricArrow': {
                bg: 'bg.hover',
              },
            }}
            backgroundColor={getRowBackgroundColor()}
            transition="0.1s"
          >
            {isAI && (
              <Td p="14px 8px" border="none" verticalAlign="top">
                <Checkbox
                  isDisabled={checkboxesDisabled}
                  isChecked={isChecked}
                  isIndeterminate={isIntermediate}
                  onChange={handleCheckboxChange}
                />
              </Td>
            )}
            <Td p="0px" border="none" pl={`${padding}px`} verticalAlign="top">
              <HStack
                p="14px 8px"
                spacing="8px"
                alignItems="start"
                height="100%"
                id={metric?.reference}
              >
                <MetricTypeIcon type={MetricTypes.text} />
                <Typography
                  id={metric?.reference}
                  variant="body"
                  className="metricTitle"
                  textDecoration="underline"
                  textDecorationColor={
                    isEqual(rowData, { metric: metric }) ? 'text.hint' : 'transparent'
                  }
                  textUnderlineOffset={'2px'}
                  transition="0.15s"
                  cursor="pointer"
                  onClick={handleRowClick}
                >
                  {metric?.shortTitle ?? metric?.title}
                </Typography>
              </HStack>
            </Td>
            <Td p="16px 8px" border="none" alignContent="flex-start">
              <TruncatableText variant="body" text={getMetricRefNumber(metric)} />
            </Td>
            {canInputAnswer ? (
              <Td p="0px" border="none" verticalAlign="top" px="8px" py="6px">
                {isBooleanMetric ? (
                  <BooleanMetricInput
                    metric={metric as MetricsTableData['metric']}
                    setRowData={setRowData}
                    companyReportingUnitId={companyReportingUnit}
                    setBooleanMetricAnswers={setBooleanMetricAnswers}
                    booleanMetricAnswers={booleanMetricAnswers}
                    isReadOnly={isReadOnly}
                  />
                ) : hasChoice ? (
                  <ChoiceMetricInput
                    metric={metric as MetricsTableData['metric']}
                    companyReportingUnitId={companyReportingUnit}
                  />
                ) : (
                  <LongTextMetricInput
                    metric={metric as MetricsTableData['metric']}
                    companyReportingUnit={companyReportingUnit}
                    rowData={rowData}
                    setRowData={setRowData}
                    isGeneratingAnswers={isGeneratingAnswers}
                    answersData={answersData}
                    generatedAnswer={generatedAnswer}
                    isReadOnly={isReadOnly}
                    isAI={isAI}
                  />
                )}
              </Td>
            ) : (
              <Box></Box>
            )}

            {/* Source */}
            {isAI && (
              <Td px="8px" border="none" verticalAlign="top">
                <MetricsAttachmentsLabel
                  row={{ metric: metric as MetricsTableData['metric'], tags: [] }}
                  companyReportingUnitId={companyReportingUnit}
                />
              </Td>
            )}

            {/* Last edited */}
            {isAI && (
              <Td px="8px" border="none" verticalAlign="top">
                {isAwaitingApproval ? (
                  <HStack spacing="4px">
                    <Avatar name="AI" size="xs" />
                    <Typography variant="body">{getLastEditedData(new Date()).date}</Typography>
                  </HStack>
                ) : (
                  <LastEditedBy
                    row={{ metric: metric as MetricsTableData['metric'], tags: [] }}
                    companyStandardId=""
                    selectedQuarter={selectedQuarter}
                    reportingUnitId={companyReportingUnit}
                  />
                )}
              </Td>
            )}

            {/* Owner */}
            {(!isReadOnly || isAI) && (
              <Td p="0px 8px" border="none" verticalAlign="top">
                <Box my="10px">
                  <MetricOwnerAvatar
                    row={{ metric: metric as MetricsTableData['metric'], tags: [] }}
                    selectedQuarter={selectedQuarter}
                    companyReportingUnit={companyReportingUnit}
                    assessmentProjectLeader={esrsAssessmentProjectLeader}
                  />
                </Box>
              </Td>
            )}

            {/* Actions */}
            <Td p="0px 8px" border="none" verticalAlign="top">
              <Box my="10px">
                <IconButton
                  aria-label="side-bar"
                  variant="ghost"
                  icon={<ArrowNarrowRightIcon />}
                  onClick={handleRowClick}
                  className="metricArrow"
                />
              </Box>
            </Td>

            {/* AI Approval */}
            {isAI && !!generatedAnswers?.length && (
              <Td border="none" textAlign="right" verticalAlign="top">
                {generatedAnswer && generatedAnswer.answer !== '-' && (
                  <AnswerApprovalCell
                    generatedAnswers={generatedAnswers ?? []}
                    setGeneratedAnswers={setGeneratedAnswers}
                    row={{ metric: metric as MetricsTableData['metric'], tags: [] }}
                    assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                    companyReportingUnitId={companyReportingUnit}
                    populateNarrativeAnswers={populateNarrativeAnswers}
                  />
                )}
              </Td>
            )}
          </Tr>

          {!canInputAnswer && (
            <AdditionalInfoRow
              metric={metric as MetricsTableData['metric']}
              companyReportingUnit={companyReportingUnit}
              placement="before"
              hasAdditionalInfo={hasAdditionalInfo}
              setHasAdditionalInfo={setHasAdditionalInfo}
            />
          )}

          {metric?.childrenMetrics.length > 0 &&
            (areChildrenLocked ? (
              <Tr
                borderTop="1px solid"
                borderColor="border.decorative"
                backgroundColor="bg.disabled"
                transition="0.1s"
              >
                <Td p="0px" border="none" pl="24px" py="16px" colSpan={4}>
                  <HStack>
                    <LockedIcon color="text.disabled" />
                    <Typography variant="body" color="text.disabled">
                      {metric.childrenMetrics.length} more data points if selected “
                      {metric.unlockCondition ? 'Yes' : 'No'}” above
                    </Typography>
                  </HStack>
                </Td>
              </Tr>
            ) : (
              metric.childrenMetrics.map((child) =>
                mapMetrics(
                  child.childMetric as MetricsTableData['metric'],
                  padding + 24,
                  metricId,
                  false,
                  answers
                )
              )
            ))}
          {!canInputAnswer && (
            <AdditionalInfoRow
              metric={metric as MetricsTableData['metric']}
              companyReportingUnit={companyReportingUnit}
              placement="after"
              setHasAdditionalInfo={setHasAdditionalInfo}
              hasAdditionalInfo={hasAdditionalInfo}
            />
          )}
          {!canInputAnswer && !hasAdditionalInfo?.[metric.reference] && (
            <Tr border="none">
              <Td p="0px" border="none" pl={`${padding + 24}px`} pb="8px !important" colSpan={5}>
                <Button
                  variant="ghost"
                  leftIcon={<AddIcon />}
                  onClick={() => {
                    setSelectedMetric(metric);
                    onOpen();
                  }}
                >
                  Additional information
                </Button>
              </Td>
            </Tr>
          )}
        </>
      );
    } else return <></>;
  };

  return (
    <>
      <ChakraTable
        border={withBorder ? '1px solid' : 'none'}
        display={withBorder ? 'inline-block' : ''}
        borderColor={withBorder ? 'border.decorative' : ''}
        borderRadius="8px"
        w="100%"
      >
        <Thead w="100%" borderY={withBorder ? '0px' : '1px solid'} borderColor="border.decorative">
          <Tr w="100%">
            {isAI && (
              <Th p="14px 8px" borderColor="border.decorative" textTransform="none" w="5%">
                <Checkbox
                  isDisabled={checkboxesDisabled}
                  isChecked={allMetricsChecked}
                  isIndeterminate={someMetricsChecked}
                  onChange={handleCheckAll}
                />
              </Th>
            )}
            <Th {...theadStyle} textTransform="none" w={isAI ? '30%' : '40%'}>
              <Typography variant="detailStrong">
                {isForBooleanMetrics ? 'Boolean data point' : 'Data point'}
              </Typography>
            </Th>
            <Th {...theadStyle} textTransform="none" w="72px">
              <Typography variant="detailStrong">Ref.</Typography>
            </Th>
            <Th {...theadStyle} textTransform="none" w="fit-content">
              <Typography variant="detailStrong">Answer</Typography>
            </Th>
            {/* Source */}
            {isAI && (
              <Th {...theadStyle} textTransform="none" w="10%">
                <Typography variant="detailStrong">Source</Typography>
              </Th>
            )}
            {/* Last Edited */}
            {isAI && (
              <Th {...theadStyle} textTransform="none" w="10%">
                <Typography variant="detailStrong">Last edited</Typography>
              </Th>
            )}
            {(!isReadOnly || isAI) && (
              <Th {...theadStyle} textTransform="none" w="10%">
                <Typography variant="detailStrong">Owner</Typography>
              </Th>
            )}
            <Th {...theadStyle} w="5%">
              {/* Actions header */}
            </Th>
            {isAI && !!generatedAnswers?.length && (
              <Th {...theadStyle} textTransform="none" w="10%">
                <HStack spacing="8px">
                  <AIIcon />
                  <Typography variant="bodyStrong">Approve</Typography>
                </HStack>
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody ref={bodyRef} w="100%">
          {metrics.map((metric) => (
            <React.Fragment key={metric.reference}>
              {mapMetrics(metric, 0, metric.reference, true, answersData)}
            </React.Fragment>
          ))}
        </Tbody>
      </ChakraTable>

      <AnswersApprovalWarningModal
        isOpen={isApprovalWarningModalOpen}
        onClose={onApprovalWarningModalClose}
        onConfirm={() => {
          const metric = metrics.find((m) => m.reference === selectedRow);

          const answerData = answersData?.find((a) => a.metricRef === selectedRow);

          if (isEqual(rowData?.metric, metric)) {
            setRowData(undefined);
          } else
            setRowData(
              !!answerData?.reportingUnits?.length || !!answerData?.subsidiaries?.length
                ? { metric: metric as MetricsTableData['metric'], sourceData: answerData }
                : { metric: metric as MetricsTableData['metric'] }
            );
        }}
      />
      <AdditionalInfoModal
        onClose={onClose}
        isOpen={isOpen}
        metric={
          (selectedMetric as MetricsTableData['metric']) ?? ({} as MetricsTableData['metric'])
        }
        companyReportingUnit={companyReportingUnit}
      />
    </>
  );
};
