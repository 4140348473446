import { Box, Grid, GridItem, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { LearnMoreDrawer, Loader, SearchInput } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';
import { useGetTargetsFormData } from './Targets.hooks';
import { DisclosureRequirementTitle } from '../DisclosureRequirementsUtilsComponents';
import { Button, EmptyState, Infobox, Tag } from 'Atoms';
import { SELECTED_MILESTONES_ID } from '../Requirement';
import { TargetsTable } from './TargetsTable';
import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import { TargetsGraphCard } from './TargetsGraphCard';
import { TargetIcon } from 'Tokens/Icons/Data';
import { useUserSetting } from 'containers/Navigation';
import { AddIcon } from 'Tokens/Icons/Function';
import { Typography } from 'Tokens';
import { TargetDisclosureRequirement } from './TargetDisclosureRequirement';
import { AddActionDrawer } from '../Actions';
import { AddTargetModal } from './AddTarget';
import { useUserData } from '@nhost/react';
import { useUserCompaniesQuery } from 'models';
import { useGetEsrsSubsidiaries } from './AddTarget/AddTargets.hooks';

export enum ViewTabs {
  currentCompany = 'Your company',
  parentCompany = 'Parent company',
  subsidiaries = 'Subsidiaries',
}

export const Targets = ({
  setMetricSidebarOpen,
}: {
  setMetricSidebarOpen: (val: boolean) => void;
}) => {
  const { companyType, loading: typeLoading } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const user = useUserData();
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();
  const { companyAssessmentId, parentAssessmentId, hasParentAssessment } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );
  const [hasClosedInfo, setHasClosedInfo] = useUserSetting('esrs-has-closed-targets-info', false);
  const {
    onOpen: onOpenActionModal,
    isOpen: isOpenActionModal,
    onClose: onCloseActionModal,
  } = useDisclosure();
  const {
    onOpen: onTargetModalOpen,
    isOpen: isTargetModalOpen,
    onClose: onTargetModalClose,
  } = useDisclosure();
  const [searchValue, setSearchValue] = useState('');
  const [selectedView, setSelectedView] = useState(ViewTabs.currentCompany);
  // const [isChartsView] = useLocalStorage('isChartView', false);
  const [isChartsView] = useState(false);
  const { reportingUnitAssessments: reportingUnits } = useAssessmentReportingUnits(
    esrsAssessmentId ?? ''
  );
  const [selectedActionId, setAction] = useState<number>();
  const [selectedTargetId, setTargetId] = useState<string>();
  const [showAll, setShowAll] = useState(false);

  const subData = useGetEsrsSubsidiaries({ assessmentId: esrsAssessmentId });
  const { data: userCompaniesData } = useUserCompaniesQuery({
    variables: {
      id: user?.id ?? '',
    },
  });
  const subsidariesIds = useMemo(() => {
    return subData.data?.esrs?.subsidiaries.map((sub) => sub.company.id);
  }, [subData]);
  const userCompanies = useMemo(
    () => userCompaniesData?.data?.companies.map((cp) => cp.company.id),
    [userCompaniesData]
  );

  const hasAccessToAllSubs = useMemo(
    () => subsidariesIds?.every((sub) => userCompanies?.includes(sub)) ?? false,
    [subsidariesIds]
  );

  console.log('subbb ', subsidariesIds, userCompanies);
  const { targetDR, combinedData, actions, subsidiaries, parentTargets, loading } =
    useGetTargetsFormData();

  const [filteredData, shouldShowButton] = useMemo(() => {
    const targetData = combinedData
      ?.filter((data) => {
        if (selectedView === 'Your company') return !data.isSubOrParent;
        return data.isSubOrParent;
      })
      .filter((data) =>
        searchValue ? data.title.toLocaleLowerCase().search(searchValue) !== -1 : true
      );
    const shouldShow = (targetData?.length ?? 0) > 3;
    return [showAll ? targetData : targetData.slice(0, 3), shouldShow];
  }, [searchValue, selectedView, combinedData, showAll]);

  const { title = '', description = '' } = targetDR || {};

  useEffect(() => localStorage.removeItem(SELECTED_MILESTONES_ID), []);

  const showInfobox = useMemo(
    () => !hasClosedInfo && !isGroup && filteredData?.some((targ) => targ.isSubOrParent),
    [hasClosedInfo, isGroup, filteredData]
  );

  if (loading || typeLoading) {
    return <Loader />;
  }

  return (
    <VStack spacing="20px" alignItems="start" w="100%">
      <DisclosureRequirementTitle title="Targets" reference={targetDR?.reference} onOpen={onOpen} />
      <VStack spacing="40px" alignItems="start" w="100%">
        <VStack alignItems="start" spacing="6px" w="100%">
          <Typography
            variant="h3"
            borderBottom="1px solid"
            w="100%"
            borderColor="border.decorative"
            paddingBottom="8px"
            marginBottom="12px"
          >
            Manage targets
          </Typography>
          {!combinedData.length ? (
            <EmptyState
              description="Empower your sustainability journey by setting targets with defined owners and linked action plans"
              icon={<TargetIcon boxSize="20px" color="text.hint" />}
              variant="white"
              component={true}
              callToAction={{
                text: 'Add target',
                variant: 'ghost',
                leftIcon: <AddIcon />,
                onClick: onTargetModalOpen,
              }}
            />
          ) : (
            <VStack spacing="16px" alignItems="start" w="100%">
              <VStack spacing="12px" alignItems="start" w="100%">
                {showInfobox && (
                  <Box w="100%">
                    <Infobox
                      status="info"
                      titleProps={{ variant: 'h3' }}
                      icon={<TargetIcon color="text.info" />}
                      title="Group company targets"
                      onClose={() => setHasClosedInfo(true)}
                      description="Review targets set by the parent company. You can also add your own targets. "
                    />
                  </Box>
                )}
                <HStack justifyContent="space-between" w="100%">
                  <HStack>
                    <Box>
                      <SearchInput
                        search={searchValue}
                        setSearch={setSearchValue}
                        placeholder="Filter"
                        withLeftIcon={false}
                        minW="200px"
                        height="28px"
                      />
                    </Box>

                    {Object.values(ViewTabs)
                      .filter((tab) =>
                        isGroup ? tab !== ViewTabs.parentCompany : tab !== ViewTabs.subsidiaries
                      )
                      .map((tab) => (
                        <Tag
                          isSelected={selectedView === tab ? true : false}
                          size="sm"
                          title={tab}
                          clickable={true}
                          onClick={() => setSelectedView(tab)}
                        />
                      ))}
                  </HStack>
                  <HStack>
                    {/* <TableViewSwitcher
                      isChartsView={isChartsView}
                      setIsChartsView={setIsChartsView}
                    /> */}
                    <Button variant="primary" onClick={onTargetModalOpen} size="sm">
                      Add target
                    </Button>
                  </HStack>
                </HStack>
              </VStack>
              <VStack alignItems="start" w="100%">
                {isChartsView ? (
                  <Grid templateColumns="repeat(3, 1fr)" gap="8px" w="100%">
                    {filteredData.map((target, index) => (
                      <GridItem w="100%" key={index}>
                        <TargetsGraphCard
                          target={target}
                          setAction={setAction}
                          openAction={onOpenActionModal}
                        />
                      </GridItem>
                    ))}
                  </Grid>
                ) : (
                  <TargetsTable
                    data={filteredData}
                    currentTab={selectedView}
                    isSearch={!!searchValue}
                    setAction={setAction}
                    openAction={onOpenActionModal}
                    setTargetId={setTargetId}
                    isGroup={isGroup}
                    hasParentAssessment={hasParentAssessment}
                  />
                )}
                {shouldShowButton && (
                  <Button variant="ghost" onClick={() => setShowAll(!showAll)}>
                    {showAll ? 'Hide' : 'Show all...'}
                  </Button>
                )}
              </VStack>
            </VStack>
          )}
        </VStack>
        <AddActionDrawer
          isOpen={isOpenActionModal}
          onClose={onCloseActionModal}
          actionToEdit={actions?.find((action) => action.id === selectedActionId)}
          reportingUnits={reportingUnits}
          isGroup={isGroup}
          disclosureRequirementRef={
            actions?.find((action) => action.id === selectedActionId)?.disclosureRequirementRef ??
            ''
          }
          materialityAssessmentId={companyAssessmentId}
          targetId={selectedTargetId}
        />
        <TargetDisclosureRequirement setMetricSidebarOpen={setMetricSidebarOpen} />
      </VStack>
      <LearnMoreDrawer
        header={title}
        isOpen={isOpen}
        onClose={onClose}
        description={description ?? ''}
      />
      <AddTargetModal
        isOpen={isTargetModalOpen}
        onClose={onTargetModalClose}
        materialStandardId={companyAssessmentId}
        parentStandardId={parentAssessmentId}
        disclosureRequirementRef={disclosureRequirementRef}
        standardRef={standardRef}
        subsidiaries={subsidiaries}
        parentTargets={parentTargets}
        targets={targetDR?.targets}
        hasAccessToAllSubs={hasAccessToAllSubs}
        isGroup={isGroup}
      />
    </VStack>
  );
};
