import { Tr, Td, HStack, useDisclosure } from '@chakra-ui/react';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { Typography } from 'Tokens';
import { RemoveIcon } from 'Tokens/Icons/Function';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Menu } from 'Molecules/Menu';
import { useGetDatapointValues } from '../QuarterInput';
import { Modal } from 'Molecules';
import { MetricsTableData, TimePeriodsEnums } from '../../..';
import { AutoResizeTextarea } from 'Atoms';
import { debounce } from 'lodash';

export const AdditionalInfoRow = ({
  metric,
  companyReportingUnit,
  placement,
  hasAdditionalInfo,
  setHasAdditionalInfo,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnit: string | undefined;
  placement: 'before' | 'after';
  hasAdditionalInfo?: { [key: string]: boolean };
  setHasAdditionalInfo?: (p: { [key: string]: boolean }) => void;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [inputValue, setInputValue] = useState<string | null>();
  const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);

  const { onDatapointChange, answer, dataPointPerYear } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnit ?? ''
  );

  const data = useMemo(
    () =>
      placement === 'before'
        ? dataPointPerYear?.metadata?.before
        : dataPointPerYear?.metadata?.after,
    [placement, dataPointPerYear]
  );

  useEffect(() => {
    if (!hasStartedTyping) setInputValue(data?.text ?? null);
  }, [data]);

  useEffect(() => {
    setHasAdditionalInfo?.({
      ...(hasAdditionalInfo ?? {}),
      [metric.reference]:
        dataPointPerYear?.metadata?.before?.isVisible === true &&
        dataPointPerYear?.metadata?.after?.isVisible === true,
    });
  }, [dataPointPerYear]);

  const onConfirm = () => {
    const metadata = {
      ...dataPointPerYear?.metadata,
      [placement]: {
        ...(data ?? {}),
        isVisible: false,
      },
    };
    onDatapointChange({
      value: dataPointPerYear?.value ?? '',
      factValue: dataPointPerYear?.factValue,
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
      metadata: metadata,
    }).then(() => onClose());
  };

  const debounceSave = useCallback(
    debounce((newValue) => {
      const metadata = {
        ...dataPointPerYear?.metadata,
        [placement]: {
          ...(data ?? {}),
          text: newValue,
        },
      };
      onDatapointChange({
        value: dataPointPerYear?.value ?? '',
        factValue: dataPointPerYear?.factValue,
        hasOptedOut: answer?.hasOptedOut ?? false,
        optOutReason: answer?.optOutReason ?? '',
        dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
        metadata: metadata,
      });
    }, 1000),
    [onDatapointChange, dataPointPerYear]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value === '' ? null : event.target.value;
    setInputValue(newValue);
    debounceSave(newValue);
    setHasStartedTyping(true);
  };

  if (data?.isVisible !== true) {
    return <></>;
  }

  return (
    <Tr borderTop="none">
      <Td p="0px" border="none" pl="24px" verticalAlign="top">
        <HStack p="14px 8px" spacing="8px" alignItems="start" height="100%">
          <MetricTypeIcon type={MetricTypes.text} />
          <Typography id={metric?.reference} variant="body">
            Additional information
          </Typography>
        </HStack>
      </Td>
      <Td p="16px 8px" border="none" alignContent="flex-start">
        <Typography variant="body">-</Typography>
      </Td>
      <Td p="0px" border="none" verticalAlign="top" px="8px" py="6px">
        <AutoResizeTextarea
          value={inputValue ?? ''}
          onChange={handleInputChange}
          minH="44px"
          variant="ghost"
          borderRadius="6px"
          lineHeight="20px"
          size="md"
          placeholder="Write your answer"
          opacity={answer?.hasOptedOut ? 0.4 : 1}
          onClick={(e) => e.stopPropagation()}
        />
      </Td>
      <Td border="none"></Td>
      <Td p="0px 8px" border="none">
        <Menu
          sections={[
            {
              actions: [
                {
                  id: 'remove',
                  title: 'Remove',
                  leftElement: <RemoveIcon />,
                  onClick: () => onOpen(),
                },
              ],
            },
          ]}
        />
      </Td>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xs"
        hasHeader={false}
        confirmText="Remove"
        confirmVariant="destructive"
        onConfirm={onConfirm}
      >
        <Typography variant="bodyStrong">
          Are you sure you want to remove the “Additional information” field?
        </Typography>
      </Modal>
    </Tr>
  );
};
