import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableProps as ChakraTableProps,
  TableRowProps,
  HStack,
  VStack,
  Box,
} from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  ColumnDefResolved,
  ExpandedState,
} from '@tanstack/react-table';
import { IconButton, TruncatableText } from 'Atoms';
import { HelpTooltip } from 'Molecules/HelpTooltip';
import { Typography, TypographyVariant } from 'Tokens';
import { HashIcon } from 'Tokens/Icons/Data';
import { ChevronRightIcon, ChevronDownIcon } from 'Tokens/Icons/Direction';
import { useEffect, useRef, useState } from 'react';

export type TableData<T> = T & { subRows?: Array<TableData<T>>; hidden?: boolean; type?: string };
type NestedTableProps<T> = {
  columns?: Array<ColumnDefResolved<TableData<T>>>;
  data?: Array<TableData<T>>;
  extra?: React.ReactNode;
  onRowClick?: (row: TableData<T>) => void;
  rowProps?: (row?: TableData<T>) => TableRowProps;
  pageSize?: number;
  headerPadding?: string;
  cellPadding?: string;
  allowSorting?: boolean;
  headerAlignment?: string;
  withBorder?: boolean;
  expanded?: boolean;
  rowIcon?: React.ReactNode;
  rowHeight?: string;
  defaultExpanded?: ExpandedState;
  headerBorderColor?: string;
  headerVariant?: TypographyVariant;
} & ChakraTableProps;

export function NestedTable<T extends { subRows?: T[] }>({
  columns,
  data,
  withBorder,
  expanded,
  borderRadius,
  rowHeight,
  defaultExpanded,
  rowProps,
  headerBorderColor,
  headerVariant,
  pageSize,
  onRowClick,
}: NestedTableProps<T>) {
  const [cellWidth, setCellWidth] = useState(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null);
  const [expandedRows, setExpandedRows] = useState<ExpandedState>({});

  const table = useReactTable<TableData<T>>({
    initialState: { expanded: expanded ? true : undefined },
    paginateExpandedRows: true,
    columns: [
      {
        ...columns?.[0],
        id: 'expander',
        cell: columns?.[0]?.cell
          ? columns[0]?.cell
          : ({ row, getValue }) => {
              return (
                <HStack pl={`${row.depth * 24}px`} spacing="8px">
                  {row.getCanExpand() ? (
                    <IconButton
                      variant={'ghost'}
                      size="xs"
                      onClick={row.getToggleExpandedHandler()}
                      aria-label="expand"
                      icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    />
                  ) : (
                    <HashIcon />
                  )}
                  <TruncatableText
                    variant={row.depth === 0 ? 'bodyStrong' : 'body'}
                    text={getValue()}
                  />
                </HStack>
              );
            },
      },
      ...(columns?.length
        ? columns.slice(1).map((column, index) => ({
            ...column,
            id: `column-${index}`,
          }))
        : []),
    ],
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpandedRows,
    state: {
      expanded: expandedRows,
    },
  });

  useEffect(() => {
    if (expanded) {
      table.toggleAllRowsExpanded(true);
    } else if (!!defaultExpanded) {
      const mergedExpandedRows: ExpandedState = Object.entries(expandedRows).reduce(
        (acc, [key, value]) => {
          if (typeof key === 'string') {
            acc[key] = value;
          }
          return acc;
        },
        { ...(defaultExpanded as Record<string, boolean>) }
      );

      setExpandedRows(mergedExpandedRows);
    }
  }, [defaultExpanded, expanded]);

  useEffect(() => {
    const handleColumnWidths = () => {
      let numberOfunsizedHeaders = 0;
      const headersSizes = table.getHeaderGroups().flatMap((headerGroup) => {
        return headerGroup.headers.map((header) => {
          if (!(header.column.columnDef.meta as any)?.width) {
            numberOfunsizedHeaders++;
            return header.column.getSize();
          } else {
            return 0;
          }
        });
      });

      const headersSizesTotal = headersSizes.reduce((a, b) => a + b, 0);

      const tableWidth = tableRef.current?.offsetWidth ?? 0;
      const newCellWidth = (tableWidth - headersSizesTotal) / numberOfunsizedHeaders;
      setCellWidth(newCellWidth);
    };

    if (document.readyState === 'complete') {
      handleColumnWidths();
    } else {
      document.addEventListener('load', handleColumnWidths);
      return () => document.removeEventListener('load', handleColumnWidths);
    }
  }, [columns, data, tableRef, tableHeaderRef, table]);

  return (
    <ChakraTable
      width="100%"
      border={withBorder ? '1px solid' : 'none'}
      display={withBorder ? 'inline-block' : ''}
      borderColor={withBorder ? 'border.decorative' : ''}
      borderRadius={borderRadius ?? '8px'}
      ref={tableRef}
    >
      <Thead ref={tableHeaderRef}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr
            key={headerGroup.id}
            width="100%"
            height={rowHeight ?? '48px'}
            borderTop={withBorder ? '' : '1px solid'}
            borderColor={headerBorderColor ?? 'border.decorative'}
          >
            {headerGroup.headers.map((header) => {
              const meta = header.column.columnDef.meta as any;
              return (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  textTransform="none"
                  padding={rowHeight ? '0px' : ''}
                  px="8px"
                  py={meta?.subtitle ? '0px' : ''}
                  width={meta?.width ?? `${cellWidth}px`}
                  letterSpacing="normal"
                  borderColor="border.decorative"
                >
                  {header.isPlaceholder ? null : (
                    <HStack alignItems="center" gap="4px">
                      <VStack gap="0px" alignItems="start">
                        <Typography variant={headerVariant ?? 'bodyStrong'}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Typography>
                        <Typography variant="micro" noOfLines={1}>
                          {meta?.subtitle}
                        </Typography>
                      </VStack>
                      {meta?.tooltip && <HelpTooltip label={meta?.tooltip} placement="bottom" />}
                    </HStack>
                  )}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table
          .getRowModel()
          .rows.filter((row) => !row.original.hidden)
          .slice(0, pageSize)
          .map((row, key) => {
            return (
              <Tr
                key={row.id}
                borderColor="border.decorative"
                height={rowHeight ?? '48px'}
                bgColor={row.original.type === 'tag' ? 'bg.muted' : ''}
                onClick={() => onRowClick?.(row.original)}
                {...rowProps?.(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Td
                      verticalAlign={(cell.column.columnDef.meta as any)?.verticalAlign ?? 'middle'}
                      key={cell.id}
                      p={(cell.column.columnDef.meta as any)?.padding ?? '0px 8px'}
                      borderColor="border.decorative"
                      border={key === table.getRowModel().rows.length - 1 ? 'none' : ''}
                      width={(cell.column.columnDef.meta as any)?.width}
                    >
                      <Box w="100%">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Box>
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
      </Tbody>
    </ChakraTable>
  );
}
