import { ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { Tooltip } from 'Atoms';
import { Typography } from 'Tokens';
import { CalculatorIcon, DescriptionIcon, HashIcon, SumIcon } from 'Tokens/Icons/Data';
import { useMemo } from 'react';

export enum MetricTypes {
  number = 'number',
  text = 'text',
  boolean = 'boolean',
  aggregated = 'aggregated',
  calculated = 'calculated',
}

const METRIC_ICONS = {
  [MetricTypes.text]: <DescriptionIcon />,
  [MetricTypes.boolean]: <DescriptionIcon />,
  [MetricTypes.number]: <HashIcon />,
  [MetricTypes.aggregated]: <SumIcon />,
  [MetricTypes.calculated]: <CalculatorIcon />,
};

export const CalculatedMetric = ({
  subMetrics,
  calculation,
}: {
  calculation?: string;
  subMetrics?: { name: string; reference: string }[];
}) => {
  const calculationString = useMemo(
    () =>
      calculation?.replace(/[a-zA-Z_]\w*/g, (match, index) => {
        const foundReference = subMetrics?.find((item) => item.reference === match);
        const replacement = foundReference ? foundReference.name : match;
        return index > 1 ? ` ${replacement}` : replacement;
      }),
    [calculation]
  );

  return (
    <VStack alignItems="start">
      <Typography variant="body" color="text.onAccent">
        This data point is calculated from other data points:
      </Typography>
      <UnorderedList>
        {subMetrics?.slice(0, 5).map((metric) => (
          <ListItem>
            <Typography variant="body" color="text.onAccent">
              {metric.name}
            </Typography>
          </ListItem>
        ))}
      </UnorderedList>
      {subMetrics && subMetrics.length > 5 && (
        <Typography variant="body" color="text.onAccent">
          and {subMetrics.length - 5} more metric{subMetrics.length > 6 && 's'}
        </Typography>
      )}
      {calculation && (
        <Typography variant="body" color="text.onAccent" maxW="330px">
          Formula: {calculationString}
        </Typography>
      )}
    </VStack>
  );
};

export const AggregatedMetric = ({
  subMetrics,
  isBusinessUnits,
  isSubsidiaries,
  isQuarterly,
}: {
  subMetrics?: { name: string; reference: string }[];
  isBusinessUnits?: boolean;
  isSubsidiaries?: boolean;
  isQuarterly?: boolean;
}) => {
  const aggregatedLabel = useMemo(() => {
    if (isBusinessUnits) return 'This data point is aggregated from business units';
    if (isSubsidiaries) return 'This data point is aggregated from subsidiaries';
    if (isQuarterly) return 'This data point is aggregated from other data points';
    return 'This data point is aggregated from other data points';
  }, [isBusinessUnits, isSubsidiaries, isQuarterly]);
  return (
    <VStack alignItems="start">
      <Typography variant="body" color="text.onAccent">
        {aggregatedLabel}
      </Typography>
      {!!subMetrics?.length && (
        <UnorderedList>
          {subMetrics?.map((metric) => (
            <ListItem>
              <Typography variant="body" color="text.onAccent">
                {metric.name}
              </Typography>
            </ListItem>
          ))}
        </UnorderedList>
      )}
    </VStack>
  );
};

export const MetricTypeIcon = ({
  type,
  calculation,
  subMetrics,
  isBusinessUnits,
  isSubsidiaries,
  isQuarterly,
}: {
  type: MetricTypes;
  calculation?: string;
  subMetrics?: { name: string; reference: string }[];
  isBusinessUnits?: boolean;
  isSubsidiaries?: boolean;
  isQuarterly?: boolean;
}) => {
  const iconLabel = useMemo(() => {
    if (type === MetricTypes.text) return 'Narrative data point';
    if (type === MetricTypes.boolean) return 'Boolean data point';
    if (type === MetricTypes.number) return 'Inputted metric';
    if (type === MetricTypes.calculated)
      return <CalculatedMetric calculation={calculation} subMetrics={subMetrics} />;
    if (type === MetricTypes.aggregated)
      return (
        <AggregatedMetric
          isBusinessUnits={isBusinessUnits}
          isQuarterly={isQuarterly}
          isSubsidiaries={isSubsidiaries}
          subMetrics={subMetrics}
        />
      );
  }, [type]);

  return (
    <Tooltip label={iconLabel} maxW="340px" placement="left">
      <VStack alignItems="center" justifyContent="center" h="20px">
        {METRIC_ICONS[type]}
      </VStack>
    </Tooltip>
  );
};
