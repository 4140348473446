import { TableData } from 'Molecules/NestedTable';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { useLastEdited } from './MetricsUtils';
import { HStack, Image, Tooltip } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { useParams } from 'react-router-dom';
import { UserAvatar } from 'Organisms';
import { DatapointSourceEnum } from '../../Requirement';

export const LastEditedBy = ({
  row,
  companyStandardId,
  selectedQuarter,
  metricLevel,
  reportingUnitId,
}: {
  row: TableData<MetricsTableData>;
  companyStandardId: string;
  selectedQuarter: string;
  metricLevel?: boolean;
  reportingUnitId?: string;
}) => {
  const { esrsAssessmentId } = useParams();
  const lastEdited = useLastEdited(
    esrsAssessmentId ?? '',
    row,
    companyStandardId,
    selectedQuarter,
    metricLevel,
    reportingUnitId
  );
  return lastEdited.user ? (
    <HStack spacing="4px">
      {lastEdited.source === DatapointSourceEnum.moreScope ? (
        <Tooltip label="MoreScope">
          <Image width="20px" src="/assets/moreScope.svg" />
        </Tooltip>
      ) : (
        <UserAvatar user={lastEdited.user} size="xs" />
      )}
      <Typography variant="body">{lastEdited.date}</Typography>
    </HStack>
  ) : (
    <Typography variant="body">—</Typography>
  );
};
