import {
  HStack,
  Td,
  Tr,
  VStack,
  Table as ChakraTable,
  Thead,
  Th,
  Box,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react';
import { Alert, Button, NumberInputProps, Tag, TagVariant, VisibleIf } from 'Atoms';
import { Typography } from 'Tokens';
import { HelpTooltip } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { CompanyFinancialResults } from './Financials.hooks';
import React, { useState, useEffect, useMemo, useCallback, ReactNode } from 'react';
import {
  getOtherFinancials,
  FINANCIAL_SECTIONS,
  aggregateFinancials,
  allFinancialSections,
  createBareFinancials,
} from '../../utils/financials';
import {
  AdaptationSectionsEnum,
  AttachmentBox,
  BareFinancials,
  Financials,
  FinancialsFieldsFragment_,
  GetFinancialsDocumentationByIdDocument_,
  NoteHistory,
  ScoreSectionsEnum,
  ShortUser,
  useGetFinancialsDocumentationByIdQuery,
} from 'models';
import { useCurrentCompany } from 'utils/hooks';
import { StateStatus } from 'Molecules/CardStatus';
import { InputCard } from 'Molecules/InputCard';
import { omit } from 'lodash';
import { formatNumber } from 'utils/numbers';
import { Tooltip } from 'Atoms/Tooltip';
import { TruncatableText } from 'Atoms/TruncatableText';
import { InputCardDocumentationProps } from 'Molecules/InputCard/InputCardDocumentation';
import { useUserData } from '@nhost/react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import { CalculatorIcon } from 'Tokens/Icons/Data';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { NumberInputWithError } from 'Molecules/NumberInputWithError';
import { ApolloError } from '@apollo/client/errors';
import { CheckIcon, LoaderIcon, WarningIcon } from 'Tokens/Icons/Status';

type FinancialTableProps = {
  businessUnit: CompanyFinancialResults['businessUnits'][number];
  onUpdate: (businessUnit: CompanyFinancialResults['businessUnits'][number]) => void;
  isLocked: boolean;
  isSaving: boolean;
  saveError: ApolloError | undefined;
};

type ActivityItem = Financials & {
  name: string;
};

const MAX_ALLOWED_NUMBER = 900000000000000;
// const MIN_ALLOWED_NUMBER = 0;

const allFinancialSectionKeys = FINANCIAL_SECTIONS.map((section) => section.key);

const createFinancialStatusTag = ({
  isSaving,
  saveError,
  hasInputError,
  hasEdited,
}: {
  isSaving?: boolean;
  saveError?: ApolloError;
  hasInputError?: boolean;
  hasEdited?: boolean;
}): {
  leftIcon?: ReactNode;
  text?: string;
  variant?: TagVariant;
  visibility: 'hidden' | 'visible';
} => {
  if (isSaving) {
    return {
      leftIcon: <LoaderIcon boxSize="16px" color="inherit" />,
      text: 'Saving...',
      variant: 'info',
      visibility: 'visible',
    };
  }
  if (saveError || hasInputError) {
    return {
      leftIcon: <WarningIcon boxSize="16px" color="inherit" />,
      text: 'Not saved',
      variant: 'warning',
      visibility: 'visible',
    };
  }
  if (hasEdited) {
    return {
      leftIcon: <CheckIcon boxSize="16px" color="inherit" />,
      text: 'Saved',
      variant: 'success',
      visibility: 'visible',
    };
  }

  return {
    visibility: 'hidden',
  };
};

export const FinancialTableFooter = ({
  isTotal = false,
  title,
  financials,
  helpLabel,
  filteredSections = allFinancialSectionKeys,
}: {
  isTotal?: boolean;
  title: string;
  financials?: Partial<Financials>;
  helpLabel?: string;
  filteredSections?: (ScoreSectionsEnum | AdaptationSectionsEnum)[];
}) => {
  const renderTableCells = useCallback(
    (sections: string[]) => {
      return sections.map((section) => (
        <Td borderBottom="0px" key={`${financials?.id}-${section}`} px="8px">
          <HStack width="100%" justifyContent="flex-end">
            <Typography variant={isTotal ? 'h4' : 'body'} maxWidth="200px" padding="0px">
              {formatNumber(financials?.[section as keyof Financials] ?? 0)}
            </Typography>
          </HStack>
        </Td>
      ));
    },
    [financials, isTotal]
  );

  return (
    <Tr>
      <Td borderBottom="0px" px="8px">
        <HStack spacing="10px">
          <CalculatorIcon color="text.hint" />
          <Typography variant={isTotal ? 'h4' : 'body'}>{title}</Typography>
          {helpLabel && <HelpTooltip label={helpLabel} />}
        </HStack>
      </Td>
      {renderTableCells(filteredSections)}
    </Tr>
  );
};

export const DifferenceRow = ({
  title,
  helpLabel,
  totalFinancials,
  companyFinancials,
  filteredSections = allFinancialSectionKeys,
}: {
  title: string;
  helpLabel?: string;
  totalFinancials: Partial<Financials> | null | undefined;
  companyFinancials: Partial<Financials> | undefined;
  filteredSections: (ScoreSectionsEnum | AdaptationSectionsEnum)[];
}) => {
  type FinancialsKeys = keyof Partial<FinancialsFieldsFragment_>;

  const renderDifferenceCells = useCallback(
    (sections: FinancialsKeys[]) => {
      return sections.map((section) => {
        const total = totalFinancials?.[section] || 0;
        const company = companyFinancials?.[section] || 0;
        const difference = total - company;
        return (
          <Td borderBottom="0px" key={`difference-${section}`} px="8px">
            <HStack width="100%" justifyContent="flex-end">
              <Typography variant="h4" maxWidth="200px" padding="0px">
                {formatNumber(difference ?? 0)}
              </Typography>
            </HStack>
          </Td>
        );
      });
    },
    [totalFinancials, companyFinancials]
  );

  return (
    <Tr>
      <Td borderBottom="0px" px="8px">
        <HStack spacing="10px">
          <CalculatorIcon color="text.hint" />
          <Typography variant="h4">{title}</Typography>
          {helpLabel && <HelpTooltip label={helpLabel} />}
        </HStack>
      </Td>
      {renderDifferenceCells(filteredSections)}
    </Tr>
  );
};

export const MemofiedFinancialInput = React.memo(
  (props: NumberInputProps & { onHasErrorChange?: (hasError: boolean) => void }) => {
    if (props.isLocked) {
      return <Typography marginRight="15px">{props.value}</Typography>;
    }
    return (
      <>
        <NumberInputWithError
          {...props}
          onHasErrorChange={props.onHasErrorChange}
          keepWithinRange
          clampValueOnBlur
          value={props.value}
          onChange={props.onChange}
          justifyContent="flex-end"
          fontWeight={undefined}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </>
    );
  }
);

export const FinancialTableColumnHeader = ({
  title,
  helpLabel,
  caption,
}: {
  title: string;
  helpLabel?: string;
  caption?: string;
}) => {
  return (
    <Th key={title} padding="8px" letterSpacing="normal">
      <HStack spacing="8px" justifyContent={caption ? 'flex-end' : 'flex-start'}>
        <VStack alignItems={caption ? 'flex-end' : 'flex-start'} spacing="2px">
          <Typography variant="detailStrong" textTransform="capitalize" noOfLines={2}>
            {title}
          </Typography>
          {caption && <Typography variant="micro">{caption}</Typography>}
        </VStack>
        {helpLabel && (
          <VStack justifyContent={'center'}>
            <HelpTooltip label={helpLabel} />
          </VStack>
        )}
      </HStack>
    </Th>
  );
};

export const BusinessUnitFinancialTable = React.memo(
  ({ businessUnit, onUpdate, isLocked, isSaving, saveError }: FinancialTableProps) => {
    const { t } = useTranslation(['common', 'bUnits']);
    const { company } = useCurrentCompany();
    const user: ShortUser | null = useUserData();

    const [activeSection, setActiveSection] = useState<string>();
    const [financials, setFinancials] = useState<ActivityItem[]>([]);
    const [notEligible, setNotEligible] = useState<ActivityItem>();
    const {
      isOpen: isAttachmentDrawerOpen,
      onOpen: onAttachmentDrawerOpen,
      onClose: onAttachmentDrawerClose,
    } = useDisclosure();

    const [hasEdited, setHasEdited] = useState(false);
    const [hasInputError, setHasInputError] = useState(false);

    useEffect(() => {
      if (isSaving && !hasEdited) {
        setHasEdited(true);
      }
    }, [isSaving]);

    const { data: documentationData } = useGetFinancialsDocumentationByIdQuery({
      variables: {
        financialsId: businessUnit?.financials?.id,
      },
      skip: !businessUnit?.financials?.id,
    });

    const attachmentBox: AttachmentBox | undefined = useMemo(
      () => documentationData?.Financials_by_pk?.attachmentBox ?? undefined,
      [documentationData]
    );

    const noteHistory: NoteHistory | undefined = useMemo(
      () => documentationData?.Financials_by_pk?.noteHistory ?? undefined,
      [documentationData]
    );

    useEffect(() => {
      const activities = businessUnit.activities.map((act) => ({
        ...(act.financials as Financials),
        name: act.activity?.name ?? 'NA',
      }));
      const notEl = {
        ...getOtherFinancials(activities, businessUnit.financials ?? undefined),
        id: businessUnit?.financials?.id,
        name: t('bUnits:financials.other'),
      };
      setFinancials(activities);
      setNotEligible(notEl);
    }, [businessUnit]);

    const eligible = useMemo(() => {
      return aggregateFinancials(financials);
    }, [financials]);

    const handleUpdate = (newNotEligible: BareFinancials, newActivities: ActivityItem[]) => {
      const newBUTotal = aggregateFinancials([...newActivities, newNotEligible]);

      const newBuFinancials: CompanyFinancialResults['businessUnits'][number] = {
        ...businessUnit,
        financials: {
          ...businessUnit?.financials,
          ...newBUTotal,
          isEstimate: businessUnit.financials?.isEstimate,
        } as Financials,
        activities: businessUnit.activities.map((act) => {
          const newAct = newActivities.find((a) => a.id === act.financials?.id);
          return {
            ...act,
            financials: {
              ...act?.financials,
              ...newAct,
              isEstimate: businessUnit.financials?.isEstimate,
            } as Financials,
          };
        }),
      };
      onUpdate(newBuFinancials);
    };

    const showDocumentation: InputCardDocumentationProps = useMemo(() => {
      return {
        isHidden: isLocked,
        currentAuthor: user ?? undefined,
        attachmentBox: attachmentBox,
        noteHistory: noteHistory,
        openAttachmentDrawer: onAttachmentDrawerOpen,
        refetchQueries: [GetFinancialsDocumentationByIdDocument_],
      };
    }, [businessUnit, noteHistory, attachmentBox, user, documentationData]);

    const saveStatusTag = useMemo(
      () => createFinancialStatusTag({ isSaving, saveError, hasEdited, hasInputError }),
      [isSaving, saveError, isLocked, hasEdited, hasInputError]
    );

    return (
      <AttachmentDrawer
        isOpen={isAttachmentDrawerOpen}
        refetch={[GetFinancialsDocumentationByIdDocument_]}
        onClose={onAttachmentDrawerClose}
        attachmentBox={attachmentBox}
      >
        <InputCard
          documentation={showDocumentation}
          header={{
            title: businessUnit.businessUnit?.name ?? 'NA',
            status: businessUnit.financials?.isEstimate ? StateStatus.todo : StateStatus.done,
            actions: isLocked
              ? []
              : [
                  <Button
                    variant="ghost"
                    size="md"
                    isDisabled={!!saveError || !!hasInputError || isSaving}
                    onClick={() => {
                      onUpdate({
                        ...businessUnit,
                        activities: businessUnit.activities.map((act) => ({
                          ...act,
                          financials: {
                            ...act.financials,
                            isEstimate: !businessUnit.financials?.isEstimate ?? true,
                          } as Financials,
                        })),
                        financials: {
                          ...businessUnit?.financials,
                          isEstimate: !businessUnit.financials?.isEstimate,
                        } as Financials,
                      });
                    }}
                  >
                    {businessUnit.financials?.isEstimate
                      ? t('common:actions.done')
                      : t('common:actions.edit')}
                  </Button>,
                  <Tag
                    visibility={saveStatusTag.visibility}
                    leftIcon={saveStatusTag.leftIcon}
                    variant={saveStatusTag.variant}
                    transition="0.3s ease-in-out"
                  >
                    {saveStatusTag?.text}
                  </Tag>,
                ],
          }}
          {...{ minWidth: '800px' }}
        >
          <VStack>
            <VisibleIf condition={!!saveError || !!hasInputError}>
              <Alert
                status="info"
                closable={false}
                title="Answer could not be saved automatically. Please save manually."
              />
            </VisibleIf>
            <ChakraTable>
              <Thead>
                <FinancialTableColumnHeader title={t('common:activity')} />
                {allFinancialSections.map((section) => (
                  <FinancialTableColumnHeader
                    key={`header-${businessUnit.id}-${section}`}
                    title={t(
                      `common:financials.${section}${
                        section.includes('adapt') ? t('common:words.short') : ''
                      }`
                    )}
                    helpLabel={t(`common:financials.${section}Tooltip`)}
                    caption={company?.currency}
                  />
                ))}
              </Thead>
              {[...financials, notEligible].map((item) => (
                <Tr key={item?.id} height="64px">
                  <Td py="14px" verticalAlign="top" px="8px">
                    <TruncatableText text={item?.name ?? ''} variant="body" />
                  </Td>
                  {allFinancialSections.map((section) => {
                    const comparingSection = section.toLowerCase().includes(ScoreSectionsEnum.opex)
                      ? t('common:financials.opex')
                      : t('common:financials.capex');
                    const max = section.includes('adapt')
                      ? section.toLowerCase().includes(ScoreSectionsEnum.opex)
                        ? item?.opex
                        : item?.capex
                      : MAX_ALLOWED_NUMBER;
                    const min =
                      section === ScoreSectionsEnum.capex
                        ? item?.adaptationCapex
                        : section === ScoreSectionsEnum.opex
                          ? item?.adaptationOpex
                          : 0;

                    const isInvalid = section.includes('adapt')
                      ? section.toLowerCase().includes(ScoreSectionsEnum.opex)
                        ? item?.[section] > item?.opex
                        : item?.[section] > item?.capex
                      : section === ScoreSectionsEnum.capex
                        ? item?.[section] < item?.adaptationCapex
                        : section === ScoreSectionsEnum.opex
                          ? item?.[section] < item?.adaptationOpex
                          : item?.[section] > MAX_ALLOWED_NUMBER;

                    const errorMessage =
                      isInvalid && activeSection === section
                        ? item?.[section] > MAX_ALLOWED_NUMBER
                          ? `${t('bUnits:financials.maxAllowed', { maxValue: MAX_ALLOWED_NUMBER })}`
                          : section.includes('adapt')
                            ? `${t('bUnits:financials.lowerValue')} ${comparingSection} (${max})`
                            : `${t(
                                'bUnits:financials.greaterValue'
                              )} Adaptation ${comparingSection} (${min})`
                        : '';

                    return (
                      <Td
                        key={`${item?.id}-${section}`}
                        pt="10px"
                        pb="0px"
                        px="8px"
                        textAlign="right"
                        verticalAlign="top"
                      >
                        <Tooltip
                          backgroundColor="bg.critical.accent"
                          placement="top"
                          label={errorMessage}
                          isOpen={true}
                        >
                          <Box>
                            <MemofiedFinancialInput
                              onHasErrorChange={setHasInputError}
                              key={`${item?.id}-${section}`}
                              id={`${item?.id}-${section}`}
                              width="100%"
                              isLocked={isLocked}
                              value={item?.[section as keyof ActivityItem]}
                              isInvalid={activeSection === section && isInvalid}
                              isDisabled={!businessUnit.financials?.isEstimate}
                              max={
                                // Max for adaptation should always be total of that financial section, ex: max for adaptation opex is total opex
                                max
                              }
                              min={min}
                              onFocus={() => {
                                setActiveSection(section);
                              }}
                              onChange={(num) => {
                                if (item?.id === notEligible?.id) {
                                  // Treat not eligible as a special case
                                  handleUpdate(
                                    {
                                      ...notEligible,
                                      [section as keyof ActivityItem]: num,
                                    } as BareFinancials,
                                    financials.map((f) => omit(f, ['name']) as ActivityItem)
                                  );
                                } else
                                  handleUpdate(
                                    notEligible as BareFinancials,
                                    financials.map((f) => {
                                      const fWithoutName = omit(f, ['name']) as ActivityItem;
                                      if (fWithoutName.id === item?.id) {
                                        return {
                                          ...fWithoutName,
                                          [section as keyof ActivityItem]: num,
                                        };
                                      }
                                      return fWithoutName;
                                    })
                                  );
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Td>
                    );
                  })}
                </Tr>
              ))}
              <FinancialTableFooter
                isTotal
                title={t('bUnits:financials.total')}
                financials={businessUnit.financials ?? undefined}
                helpLabel={t('common:financials.totalTooltip')}
              />
              <FinancialTableFooter
                title={t('bUnits:financials.notEligible')}
                financials={notEligible}
                helpLabel={t('common:financials.notEligibleTooltip')}
              />
              <FinancialTableFooter
                title={t('bUnits:financials.eligible')}
                financials={eligible}
                helpLabel={t('common:financials.eligibleTooltip')}
              />
            </ChakraTable>
          </VStack>
        </InputCard>
      </AttachmentDrawer>
    );
  }
);

export const CompanyNotEligibleTable = React.memo(
  ({
    notEligible,
    isEstimate,
    onUpdate,
    isLocked,
    isSaving,
    saveError,
    isLoading,
  }: {
    isEstimate: boolean;
    notEligible: BareFinancials;
    onUpdate: (newFinancials: BareFinancials) => void;
    isLocked: boolean;
    isSaving: boolean;
    isLoading: boolean;
    saveError: ApolloError | undefined;
  }) => {
    const { company } = useCurrentCompany();
    const { t } = useTranslation(['common', 'bUnits']);
    const [activeSection, setActiveSection] = useState<string>();

    const [hasEdited, setHasEdited] = useState(false);
    const [hasInputError, setHasInputError] = useState(false);

    useEffect(() => {
      if (isSaving && !hasEdited) {
        setHasEdited(true);
      }
    }, [isSaving]);

    const saveStatusTag = useMemo(
      () => createFinancialStatusTag({ isSaving, saveError, hasEdited, hasInputError }),
      [isSaving, saveError, isLocked, hasEdited, hasInputError]
    );

    if (isLoading || isNaN(notEligible.revenue)) {
      return <Skeleton height="400px" />;
    }

    return (
      <InputCard
        header={{
          title: t('bUnits:financials.companyOther'),
          status: isEstimate ? StateStatus.todo : StateStatus.done,
          actions: [
            <Tag
              visibility={saveStatusTag.visibility}
              leftIcon={saveStatusTag.leftIcon}
              variant={saveStatusTag.variant}
              transition="0.3s ease-in-out"
            >
              {saveStatusTag?.text}
            </Tag>,
          ],
        }}
        {...{ minWidth: '800px' }}
      >
        <VStack>
          <VisibleIf condition={!!saveError || !!hasInputError}>
            <Alert
              status="info"
              closable={false}
              title="Answer could not be saved automatically. Please save manually."
            >
              <Button size="sm" variant="primary" onClick={() => onUpdate(notEligible)}>
                Save
              </Button>
            </Alert>
          </VisibleIf>
          <ChakraTable>
            <Thead>
              <FinancialTableColumnHeader title={t('common:activity')} />
              {allFinancialSections.map((section) => (
                <FinancialTableColumnHeader
                  key={`header-company-not-eligible-${section}`}
                  title={t(
                    `common:financials.${section}${
                      section.includes('adapt') ? t('common:words.short') : ''
                    }`
                  )}
                  helpLabel={t(`common:financials.${section}Tooltip`)}
                  caption={company?.currency}
                />
              ))}
            </Thead>
            <Tr height="64px">
              <Td py="14px" verticalAlign="top" px="8px">
                <TruncatableText text={t('bUnits:financials.other')} variant="body" />
              </Td>
              {allFinancialSections.map((section) => {
                const comparingSection = section.toLowerCase().includes(ScoreSectionsEnum.opex)
                  ? t('common:financials.opex')
                  : t('common:financials.capex');
                const max = section.includes('adapt')
                  ? section.toLowerCase().includes(ScoreSectionsEnum.opex)
                    ? notEligible?.opex
                    : notEligible?.capex
                  : MAX_ALLOWED_NUMBER;
                const min =
                  section === ScoreSectionsEnum.capex
                    ? notEligible?.adaptationCapex
                    : section === ScoreSectionsEnum.opex
                      ? notEligible?.adaptationOpex
                      : 0;

                const isInvalid = section.includes('adapt')
                  ? section.toLowerCase().includes(ScoreSectionsEnum.opex)
                    ? notEligible?.[section] > notEligible?.opex
                    : notEligible?.[section] > notEligible?.capex
                  : section === ScoreSectionsEnum.capex
                    ? notEligible?.[section] < notEligible?.adaptationCapex
                    : section === ScoreSectionsEnum.opex
                      ? notEligible?.[section] < notEligible?.adaptationOpex
                      : notEligible?.[section] > MAX_ALLOWED_NUMBER;

                const errorMessage =
                  isInvalid && activeSection === section
                    ? section.includes('adapt')
                      ? `${t('bUnits:financials.lowerValue')} ${comparingSection} (${max})`
                      : `${t(
                          'bUnits:financials.greaterValue'
                        )} Adaptation ${comparingSection} (${min})`
                    : '';

                return (
                  <Td
                    key={`company-other-${section}`}
                    pt="10px"
                    pb="0px"
                    px="8px"
                    textAlign="right"
                    verticalAlign="top"
                  >
                    <Tooltip
                      backgroundColor="bg.critical.accent"
                      placement="top"
                      label={errorMessage}
                      isOpen={true}
                    >
                      <Box>
                        <MemofiedFinancialInput
                          onHasErrorChange={setHasInputError}
                          key={`company-other-${section}`}
                          width="100%"
                          value={notEligible?.[section as keyof BareFinancials]}
                          isInvalid={activeSection === section && isInvalid}
                          max={max}
                          min={min}
                          isLocked={isLocked}
                          onFocus={() => {
                            setActiveSection(section);
                          }}
                          onChange={(num) => {
                            onUpdate({
                              ...notEligible,
                              [section as keyof Financials]: num,
                            } as BareFinancials);
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Td>
                );
              })}
            </Tr>
            <FinancialTableFooter
              isTotal
              title={t('bUnits:financials.total')}
              financials={notEligible}
              helpLabel={t('common:financials.totalTooltip')}
            />
            <FinancialTableFooter
              title={t('bUnits:financials.notEligible')}
              financials={notEligible}
              helpLabel={t('common:financials.notEligibleTooltip')}
            />
            <FinancialTableFooter
              title={t('bUnits:financials.eligible')}
              financials={createBareFinancials(0, 0, 0, 0, 0)}
              helpLabel={t('common:financials.eligibleTooltip')}
            />
          </ChakraTable>
        </VStack>
      </InputCard>
    );
  }
);

export const FinancialSummary = ({
  companyResults,
  isLocked,
}: {
  companyResults: CompanyFinancialResults;
  isLocked: boolean;
}) => {
  const { company } = useCurrentCompany();
  const { companyFinancials } = companyResults;
  const { t } = useTranslation('common');
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const user = useUserData();

  const { data: documentationData } = useGetFinancialsDocumentationByIdQuery({
    variables: {
      financialsId: companyFinancials?.id,
    },
    skip: !companyFinancials?.id,
  });

  const attachmentBox = useMemo(
    () => documentationData?.Financials_by_pk?.attachmentBox as AttachmentBox,
    [documentationData]
  );

  const noteHistory = useMemo(
    () => documentationData?.Financials_by_pk?.noteHistory as NoteHistory,
    [documentationData]
  );

  const { notEligible, eligible } = useMemo(() => {
    const notEl = getOtherFinancials(
      companyResults?.businessUnits.flatMap((b) =>
        b.activities.map((a) => a.financials ?? undefined)
      ),
      companyFinancials
    );
    const el = aggregateFinancials(
      companyResults.businessUnits
        .map((b) => b.activities.map((a) => a.financials))
        .flat() as Financials[]
    );
    return { notEligible: notEl, eligible: el };
  }, [companyResults]);

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      isHidden: isLocked,
      currentAuthor: user as ShortUser,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onAttachmentDrawerOpen,
      refetchQueries: [GetFinancialsDocumentationByIdDocument_],
    };
  }, [companyFinancials, noteHistory, attachmentBox, user, documentationData, isLocked]);

  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      refetch={[GetFinancialsDocumentationByIdDocument_]}
      onClose={onAttachmentDrawerClose}
      attachmentBox={attachmentBox}
    >
      <InputCard
        documentation={showDocumentation}
        header={{
          title: t('common:financials.summaryHeader'),
          status: companyFinancials?.isEstimate ? StateStatus.todo : StateStatus.done,
        }}
        {...{ minWidth: '800px' }}
      >
        <ChakraTable>
          <Thead>
            <FinancialTableColumnHeader title="" />
            {allFinancialSections.map((section) => (
              <FinancialTableColumnHeader
                key={`header-summary-${section}`}
                title={t(
                  `common:financials.${section}${
                    section.includes('adapt') ? t('common:words.short') : ''
                  }`
                )}
                helpLabel={t(`common:financials.${section}Tooltip`)}
                caption={company?.currency}
              />
            ))}
          </Thead>
          <FinancialTableFooter
            isTotal
            title={t('bUnits:financials.total')}
            financials={companyFinancials ?? undefined}
            helpLabel={t('common:financials.totalTooltip')}
          />
          <FinancialTableFooter
            title={t('bUnits:financials.notEligible')}
            financials={notEligible}
            helpLabel={t('common:financials.notEligibleTooltip')}
          />
          <FinancialTableFooter
            title={t('bUnits:financials.eligible')}
            financials={eligible}
            helpLabel={t('common:financials.eligibleTooltip')}
          />
        </ChakraTable>
      </InputCard>
    </AttachmentDrawer>
  );
};

export const TotalTable = ({
  companyResults,
  isLocked,
  onUpdate,
  isSaving,
  saveError,
}: {
  companyResults: CompanyFinancialResults;
  isLocked: boolean;
  onUpdate: (financials: CompanyFinancialResults) => void;
  isSaving: boolean;
  saveError: ApolloError | undefined;
}) => {
  const { company } = useCurrentCompany();
  const { companyFinancials, totalFinancials } = companyResults;
  const { t } = useTranslation(['common', 'financials']);

  const [hasEdited, setHasEdited] = useState(false);
  const [hasInputError, setHasInputError] = useState(false);

  useEffect(() => {
    if (isSaving && !hasEdited) {
      setHasEdited(true);
    }
  }, [isSaving]);

  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const user = useUserData();

  const { data: documentationData } = useGetFinancialsDocumentationByIdQuery({
    variables: {
      financialsId: totalFinancials?.id,
    },
    skip: !totalFinancials?.id,
  });

  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setIsCollapsed((prevCollapsed) => !prevCollapsed);
  };
  const chevronIcon = isCollapsed ? <ChevronRightIcon /> : <ChevronDownIcon />;

  const filteredSections = useMemo(
    () => allFinancialSections.filter((section) => !section.includes('adaptation')),
    [allFinancialSections]
  );

  const attachmentBox = useMemo(
    () => documentationData?.Financials_by_pk?.attachmentBox as AttachmentBox,
    [documentationData]
  );
  const noteHistory = useMemo(
    () => documentationData?.Financials_by_pk?.noteHistory as NoteHistory,
    [documentationData]
  );

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      isHidden: isLocked,
      currentAuthor: user as ShortUser,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onAttachmentDrawerOpen,
      refetchQueries: [GetFinancialsDocumentationByIdDocument_],
    };
  }, [totalFinancials, noteHistory, attachmentBox, user, documentationData, isLocked, isCollapsed]);

  const saveStatusTag = useMemo(
    () => createFinancialStatusTag({ isSaving, saveError, hasEdited, hasInputError }),
    [isSaving, saveError, isLocked, hasEdited, hasInputError]
  );

  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      refetch={[GetFinancialsDocumentationByIdDocument_]}
      onClose={onAttachmentDrawerClose}
      attachmentBox={attachmentBox}
    >
      <InputCard
        documentation={isCollapsed ? undefined : showDocumentation}
        padding={isCollapsed ? '0px ' : undefined}
        header={{
          title: t('financials:inputCards.total.title'),
          subtitle: t('financials:inputCards.total.subtitle'),
          status: StateStatus.todo,
          onHeaderClick: toggleCollapsed,
          actions: [
            <span onClick={toggleCollapsed}>{chevronIcon}</span>,
            <Tag
              visibility={saveStatusTag.visibility}
              leftIcon={saveStatusTag.leftIcon}
              variant={saveStatusTag.variant}
              transition="0.3s ease-in-out"
            >
              {saveStatusTag?.text}
            </Tag>,
          ],
        }}
        {...{ minWidth: '800px' }}
      >
        {!isCollapsed && (
          <VStack>
            <VisibleIf condition={!!saveError || !!hasInputError}>
              <Alert
                status="info"
                closable={false}
                title="Answer could not be saved automatically. Please save manually."
              >
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() =>
                    onUpdate({
                      ...companyResults,
                      totalFinancials,
                    })
                  }
                >
                  Save
                </Button>
              </Alert>
            </VisibleIf>
            <ChakraTable>
              <Thead>
                <FinancialTableColumnHeader title={t('common:activity')} />
                {filteredSections.map((section) => (
                  <FinancialTableColumnHeader
                    key={`header-total-${section}`}
                    title={t(
                      `common:financials.${section}${
                        section.includes('adapt') ? t('common:words.short') : ''
                      }`
                    )}
                    helpLabel={t(`common:financials.${section}Tooltip`)}
                    caption={company?.currency}
                  />
                ))}
              </Thead>
              <Tr height="64px">
                <Td py="14px" px="8px" verticalAlign="top">
                  <HStack spacing="10px">
                    <Typography variant="body">
                      {t('financials:financialStatement.title')}
                    </Typography>
                    <HelpTooltip label={t('financials:financialStatement.tooltip')} />
                  </HStack>
                </Td>
                {filteredSections.map((section) => (
                  <Td
                    key={`total-${section}`}
                    pt="10px"
                    pb="0px"
                    px="8px"
                    textAlign="right"
                    verticalAlign="top"
                  >
                    <Box>
                      <MemofiedFinancialInput
                        onHasErrorChange={setHasInputError}
                        key={`company-other-${section}`}
                        width="100%"
                        value={totalFinancials?.[section as keyof BareFinancials]}
                        isLocked={isLocked}
                        onChange={(number) => {
                          onUpdate({
                            ...companyResults,
                            totalFinancials: {
                              ...totalFinancials,
                              [section as keyof BareFinancials]: number,
                            } as CompanyFinancialResults['totalFinancials'],
                          });
                        }}
                      />
                    </Box>
                  </Td>
                ))}
              </Tr>
              <DifferenceRow
                title={t('financials:differenceRow.title')}
                helpLabel={t('financials:differenceRow.tooltip')}
                totalFinancials={totalFinancials}
                companyFinancials={companyFinancials}
                filteredSections={filteredSections}
              />
            </ChakraTable>
          </VStack>
        )}
      </InputCard>
    </AttachmentDrawer>
  );
};
